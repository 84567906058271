#estados-financieros > .container[data-v-b8b8d383] {
    margin-left: auto;
    margin-right: auto;
    margin-top: 4rem;
}
#estados-financieros > .container .intro p[data-v-b8b8d383] {
        font-size: 1.5rem;
        color: var(--reserva-oscura);
}
#estados-financieros > .container .filtro-documentos[data-v-b8b8d383] {
      overflow-x: hidden;
}
#estados-financieros > .container .filtro-documentos .datepicker-container .datepicker[data-v-b8b8d383] {
          position: relative;
}
#estados-financieros > .container .filtro-documentos .datepicker-container .datepicker #document-year[data-v-b8b8d383] {
            position: absolute;
            opacity: 0;
            width: 100%;
            height: 100%;
}
#estados-financieros > .container .filtro-documentos .datepicker-container .datepicker label[data-v-b8b8d383] {
            --bg-opacity: 1;
            background-color: #ffffff;
            background-color: rgba(255, 255, 255, var(--bg-opacity));
}
#estados-financieros > .container .ano-group[data-v-b8b8d383]:not(:last-child) {
        margin-bottom: 2rem;
}
#estados-financieros > .container .ano-group .ano[data-v-b8b8d383] {
        margin-bottom: 1.5rem;
        color: var(--reserva-oscura);
}
