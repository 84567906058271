:root {
  --sm-breakpoint: 640px;
  --md-breakpoint: 768px;
  --lg-breakpoint: 1024px;
  --xl-breakpoint: 1280px;
  --xxl-breakpoint: 1600px;
}

#press-note .note-container {
    padding-top: 4rem;
    padding-bottom: 4rem;
    --bg-opacity: 1;
    background-color: #ffffff;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

#press-note .note-container h3 {
      --text-opacity: 1;
      color: #00AEf0;
      color: rgba(0, 174, 240, var(--text-opacity));
      font-size: 2rem;
    }

@media (min-width: 768px) {

#press-note .note-container h3 {
        font-size: 3rem
    }
      }

#press-note .note-container .details {
      --text-opacity: 1;
      color: #264E72;
      color: rgba(38, 78, 114, var(--text-opacity));
      margin-top: 1.25rem;
      margin-bottom: 1.25rem;
    }

@media (min-width: 768px) {

#press-note .note-container .details {
        margin-top: 2rem;

        margin-bottom: 2rem;

        font-size: 1.25rem
    }
      }

#press-note .note-container .note-content,
    #press-note .note-container p {
      display: inline;
      white-space: pre-line;
      color: var(--reserva-oscura);
    }

@media (min-width: 1024px) {

#press-note .note-container .note-content,
    #press-note .note-container p {
        font-size: 1.5rem
    }
      }

#press-note .note-container img {
      float: right;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      margin-bottom: 1.5rem;
      border-radius: 4px;
      box-shadow: 2px 2px 16px #304d6f1a;
    }

@media (min-width: 1024px) {

#press-note .note-container img {
        margin-left: 2.5rem;

        max-width: 50%
    }
      }

#press-note .related-news h3 {
      --text-opacity: 1;
      color: #264E72;
      color: rgba(38, 78, 114, var(--text-opacity));
      text-align: center;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

@media (min-width: 1280px) {

#press-note .related-news h3 {
        margin-top: 2.5rem;

        margin-bottom: 2.5rem
    }
      }

#press-note .related-news .related-news-container {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      grid-gap: 2.5rem;
      gap: 2.5rem;
    }

@media (min-width: 1280px) {

#press-note .related-news .related-news-container {
        grid-template-columns: repeat(2, minmax(0, 1fr))
    }
      }
