[data-v-ec73249b]:root {
  --sm-breakpoint: 640px;
  --md-breakpoint: 768px;
  --lg-breakpoint: 1024px;
  --xl-breakpoint: 1280px;
  --xxl-breakpoint: 1600px;
}
.responsive-grid[data-v-ec73249b] {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 2.5rem;
    gap: 2.5rem;
}
@media (min-width: 768px) {
.responsive-grid[data-v-ec73249b] {
      grid-template-columns: repeat(2, minmax(0, 1fr))
}
}
@media (min-width: 1024px) {
.responsive-grid[data-v-ec73249b] {
      grid-template-columns: repeat(3, minmax(0, 1fr))
}
}
@media (min-width: 1280px) {
.responsive-grid[data-v-ec73249b] {
      grid-template-columns: repeat(4, minmax(0, 1fr))
}
}
.solicitud[data-v-ec73249b] {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 2.5rem;
  gap: 2.5rem;
  padding: 2rem;
  --bg-opacity: 1;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  border-radius: 4px;
  box-shadow: 2px 2px 16px #1a43591a;
}
@media (min-width: 1024px) {
.solicitud[data-v-ec73249b] {
    flex-wrap: nowrap
}
}
.solicitud .img-container[data-v-ec73249b] {
    flex: 0 0 100%;
}
@media (min-width: 1024px) {
.solicitud .img-container[data-v-ec73249b] {
      flex: 0 0 50%
}
}
@media (min-width: 1280px) {
.solicitud .img-container[data-v-ec73249b] {
      flex: 0 0 33%
}
}
.solicitud .img-container img[data-v-ec73249b] {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 2px;
}
.solicitud .info[data-v-ec73249b] {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    flex: 1 1 auto;
}
.solicitud .info h3[data-v-ec73249b] {
      --text-opacity: 1;
      color: #00AEf0;
      color: rgba(0, 174, 240, var(--text-opacity));
      flex: 1 0 100%;
}
.solicitud .info p[data-v-ec73249b] {
      --text-opacity: 1;
      color: #264E72;
      color: rgba(38, 78, 114, var(--text-opacity));
      flex: 1 0 100%;
      font-size: 0.75rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 6;
}
@media (min-width: 768px) {
.solicitud .info p[data-v-ec73249b] {
        font-size: 1.25rem
}
}
.solicitud .info .link-container[data-v-ec73249b] {
      display: flex;
      justify-content: flex-end;
      flex: 1 1 100%;
}
.solicitud .info .link-container a[data-v-ec73249b] {
        --text-opacity: 1;
        color: #00AEf0;
        color: rgba(0, 174, 240, var(--text-opacity));
        font-weight: 600;
        font-size: 1.25rem;
}
