[data-v-539f6056]:root {
  --sm-breakpoint: 640px;
  --md-breakpoint: 768px;
  --lg-breakpoint: 1024px;
  --xl-breakpoint: 1280px;
  --xxl-breakpoint: 1600px;
}
#cost-effectiveness-graph[data-v-539f6056] {
  padding-top: 4rem;
  background-color: white;
}
@media (min-width: 1024px) {
#cost-effectiveness-graph[data-v-539f6056] {
    padding-top: 8rem
}
}
#cost-effectiveness-graph .cost-effectiveness-graph-container[data-v-539f6056] {
    background-image: linear-gradient(
        to right,
        var(--reserva-blanco),
        var(--reserva-blanco)
      ),
      linear-gradient(to right, var(--reserva-gris), var(--reserva-gris));
    background-position: 0 0, 0 100%;
    background-size: 100% 50%, 100% 50%;
    background-repeat: no-repeat, no-repeat;
}
#cost-effectiveness-graph .cost-effectiveness-graph-container .container[data-v-539f6056] {
      margin-left: auto;
      margin-right: auto;
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      padding-top: 5rem;
      padding-bottom: 5rem;
      background-color: var(--reserva-clara);
      border-radius: 4px;
}
@media (min-width: 640px) {
#cost-effectiveness-graph .cost-effectiveness-graph-container .container[data-v-539f6056] {
        padding-left: 5rem
}
}
@media (min-width: 1024px) {
#cost-effectiveness-graph .cost-effectiveness-graph-container .container[data-v-539f6056] {
        grid-template-columns: repeat(2, minmax(0, 1fr))
}
}
#cost-effectiveness-graph .cost-effectiveness-graph-container .container .graph-info[data-v-539f6056] {
        width: 100%;
}
@media (min-width: 1024px) {
#cost-effectiveness-graph .cost-effectiveness-graph-container .container .graph-info[data-v-539f6056] {
          padding-right: 8rem
}
}
#cost-effectiveness-graph .cost-effectiveness-graph-container .container .graph-info h2[data-v-539f6056] {
          margin-bottom: 2rem;
          color: var(--reserva-oscura);
}
#cost-effectiveness-graph .cost-effectiveness-graph-container .container .graph-info p[data-v-539f6056] {
          margin-bottom: 2rem;
          color: var(--reserva-blanco);
}
#cost-effectiveness-graph .cost-effectiveness-graph-container .container .graph-info a[data-v-539f6056] {
          width: 100%;
          text-align: center;
          margin-top: 2rem;
          background-color: var(--reserva-oscura);
}
#cost-effectiveness-graph .cost-effectiveness-graph-container .container .graph-container[data-v-539f6056] {
        max-width: 100%;
}
@media (min-width: 640px) {
#cost-effectiveness-graph .cost-effectiveness-graph-container .container .graph-container[data-v-539f6056] {
          margin-right: 5rem
}
}
#cost-effectiveness-graph .cost-effectiveness-graph-container .container .graph-container .chart[data-v-539f6056] {
          max-width: 100%;
}
#cost-effectiveness-graph .cost-effectiveness-graph-container .container .graph-container .chart .apexcharts-canvas[data-v-539f6056] {
            max-width: 100%;
}
#cost-effectiveness-graph .cost-effectiveness-graph-container .container .graph-container .chart .apexcharts-canvas svg[data-v-539f6056] {
              max-width: 100%;
}
#cost-effectiveness-graph .cost-effectiveness-graph-container .container .graph-container .chart .apexcharts-bar-area[data-v-539f6056]:hover {
              fill: var(--reserva-blanco) !important;
}
#cost-effectiveness-graph .cost-effectiveness-graph-container .container .mobile-link[data-v-539f6056] {
        margin-top: 2rem;
}
#cost-effectiveness-graph .cost-effectiveness-graph-container .container .mobile-link a[data-v-539f6056] {
          width: 100%;
          text-align: center;
          display: inline-block;
          background-color: var(--reserva-oscura);
}
@media (min-width: 1024px) {
#cost-effectiveness-graph .cost-effectiveness-graph-container .container .mobile-link a[data-v-539f6056] {
            display: none
}
}
