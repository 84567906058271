:root {
  --sm-breakpoint: 640px;
  --md-breakpoint: 768px;
  --lg-breakpoint: 1024px;
  --xl-breakpoint: 1280px;
  --xxl-breakpoint: 1600px;
}

.responsive-grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 2.5rem;
    gap: 2.5rem;
  }

@media (min-width: 768px) {

.responsive-grid {
      grid-template-columns: repeat(2, minmax(0, 1fr))
  }
    }

@media (min-width: 1024px) {

.responsive-grid {
      grid-template-columns: repeat(3, minmax(0, 1fr))
  }
    }

@media (min-width: 1280px) {

.responsive-grid {
      grid-template-columns: repeat(4, minmax(0, 1fr))
  }
    }

#activity .activity-container {
    padding-bottom: 4rem;
    --bg-opacity: 1;
    background-color: #F1F3F6;
    background-color: rgba(241, 243, 246, var(--bg-opacity));
  }

#activity .activity-container > .container {
      margin-left: auto;
      margin-right: auto;
    }

#activity .activity-container > .container h3 {
        margin-bottom: 2rem;
        --text-opacity: 1;
        color: #00AEf0;
        color: rgba(0, 174, 240, var(--text-opacity));
        font-size: 1.5rem;
      }

@media (min-width: 768px) {

#activity .activity-container > .container h3 {
          font-size: 2.5rem
      }
        }

#activity .activity-container > .container .details {
        display: flex;
        grid-gap: 2rem;
        gap: 2rem;
        margin-top: 2rem;
        margin-bottom: 2rem;
        margin-bottom: 1rem;
        --text-opacity: 1;
        color: #264E72;
        color: rgba(38, 78, 114, var(--text-opacity));
        font-size: 1.25rem;
      }

@media (min-width: 768px) {

#activity .activity-container > .container .details {
          margin-top: 4rem;

          margin-bottom: 2rem
      }
        }

#activity .activity-container > .container .details .detail {
          display: flex;
          align-items: center;
        }

#activity .activity-container > .container .details .detail [class^="icon-"],
          #activity .activity-container > .container .details .detail [class*=" icon-"] {
            margin-right: 0.75rem;
          }

#activity .activity-container > .container .activity-content,
      #activity .activity-container > .container p {
        display: inline;
        white-space: pre-line;
        color: var(--reserva-oscura);
      }

@media (min-width: 1024px) {

#activity .activity-container > .container .activity-content,
      #activity .activity-container > .container p {
          font-size: 1.5rem
      }
        }
