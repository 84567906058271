#faq .container {
    margin-left: auto;
    margin-right: auto;
  }
    #faq .container .intro {
      margin-top: 2rem;
      margin-bottom: 2rem;
      --text-opacity: 1;
      color: #264E72;
      color: rgba(38, 78, 114, var(--text-opacity));
    }
    @media (min-width: 768px) {
    #faq .container .intro {
        margin-top: 4rem;

        margin-bottom: 4rem;

        font-size: 1.5rem
    }
      }
    #faq .container .intro p {
        --text-opacity: 1;
        color: #264E72;
        color: rgba(38, 78, 114, var(--text-opacity));
      }
    @media (min-width: 768px) {
    #faq .container .intro p {
          font-size: 1.5rem
      }
        }
