[data-v-4ab2c63b]:root {
  --sm-breakpoint: 640px;
  --md-breakpoint: 768px;
  --lg-breakpoint: 1024px;
  --xl-breakpoint: 1280px;
  --xxl-breakpoint: 1600px;
}
.activity[data-v-4ab2c63b] {
  display: flex;
  flex-wrap: wrap;
  --bg-opacity: 1;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  padding: 2rem;
  grid-gap: 1rem;
  gap: 1rem;
  border-radius: 4px;
  box-shadow: 2px 2px 16px #1a43591a;
}
.activity[data-v-4ab2c63b]:not(:last-child) {
    margin-bottom: 1rem;
}
@media (min-width: 1024px) {
.activity[data-v-4ab2c63b]:not(:last-child) {
      margin-bottom: 1.5rem
}
}
@media (min-width: 1024px) {
.activity[data-v-4ab2c63b] {
    flex-wrap: nowrap;

    grid-gap: 2.5rem;

    gap: 2.5rem
}
}
.activity .img-container[data-v-4ab2c63b] {
    height: 190px;
    flex: 0 0 100%;
}
@media (min-width: 640px) {
.activity .img-container[data-v-4ab2c63b] {
      min-height: 240px
}
}
@media (min-width: 1024px) {
.activity .img-container[data-v-4ab2c63b] {
      min-height: 190px;

      flex: 0 0 405px
}
}
@media (min-width: 1024px) and (max-width: 1280px) {
.activity .img-container[data-v-4ab2c63b] {
      height: 100%
}
}
@media (min-width: 1280px) {
.activity .img-container[data-v-4ab2c63b] {
      min-height: 284px;

      flex: 0 0 550px
}
}
.activity .img-container img[data-v-4ab2c63b] {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 2px;
}
.activity .info-container[data-v-4ab2c63b] {
    display: flex;
    align-content: space-between;
    flex-wrap: wrap;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    flex: 1 1 auto;
}
@media (min-width: 1024px) {
.activity .info-container[data-v-4ab2c63b] {
      grid-gap: 1.5rem;

      gap: 1.5rem
}
}
.activity .info-container .title[data-v-4ab2c63b] {
      --text-opacity: 1;
      color: #00AEf0;
      color: rgba(0, 174, 240, var(--text-opacity));
      flex: 1 0 100%;
      font-size: 1rem;
}
@media (min-width: 768px) {
.activity .info-container .title[data-v-4ab2c63b] {
        font-size: 2rem
}
}
.activity .info-container .summary[data-v-4ab2c63b] {
      --text-opacity: 1;
      color: #264E72;
      color: rgba(38, 78, 114, var(--text-opacity));
      flex: 1 0 100%;
      font-size: 14px;
}
@media (min-width: 768px) {
.activity .info-container .summary[data-v-4ab2c63b] {
        font-size: 1.25rem
}
}
.activity .info-container .bottom-info[data-v-4ab2c63b] {
      display: flex;
      justify-content: space-between;
      flex: 1 0 100%;
}
@media (min-width: 768px) {
.activity .info-container .bottom-info[data-v-4ab2c63b] {
        font-size: 1.25rem
}
}
.activity .info-container .bottom-info .tags[data-v-4ab2c63b] {
        --text-opacity: 1;
        color: #264E72;
        color: rgba(38, 78, 114, var(--text-opacity));
}
.activity .info-container .bottom-info .tags li[data-v-4ab2c63b] {
          display: flex;
          align-items: center;
}
.activity .info-container .bottom-info .tags li [class^="icon-"][data-v-4ab2c63b]:before, .activity .info-container .bottom-info .tags li [class*=" icon-"][data-v-4ab2c63b]:before {
              margin-right: 0.75rem;
              margin-top: 4px;
}
.activity .info-container .bottom-info .link-container a[data-v-4ab2c63b] {
          --text-opacity: 1;
          color: #00AEf0;
          color: rgba(0, 174, 240, var(--text-opacity));
          font-weight: 700;
}
@media (min-width: 768px) {
.activity .info-container .bottom-info .link-container a[data-v-4ab2c63b] {
            font-size: 1.25rem
}
}
