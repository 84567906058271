:root {
  --sm-breakpoint: 640px;
  --md-breakpoint: 768px;
  --lg-breakpoint: 1024px;
  --xl-breakpoint: 1280px;
  --xxl-breakpoint: 1600px;
}

#header {
  background-color: var(--reserva-blanco);
}

#header .container {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
  }

#header .container .logo-container {
      display: block;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }

#header .container .logo-container .logo {
        object-fit: cover;
        height: 64px;
        width: 233px;
        margin: 24px 0;
      }

@media (max-width: 639px) {

#header .container .logo-container .logo {
          width: 140px
      }
        }

@media (min-width: 1280px) {

#header .container .logo-container .logo {
          margin: 0
      }
        }

#header .container .main-menu .desktop-menu {
        display: none;
        height: 100%;
      }

@media (min-width: 1280px) {

#header .container .main-menu .desktop-menu {
          display: flex;

          flex-wrap: wrap;

          align-content: center
      }
        }

#header .container .main-menu .desktop-menu .menu-item {
          position: relative;
          display: flex;
          align-items: center;
          height: 100%;
          padding: 28px 1rem 28px 0;
          margin: 0 17px;
          color: var(--reserva-oscura);
        }

@media (--xl-breakpoint) {

#header .container .main-menu .desktop-menu .menu-item {
            margin: 0 24px
        }
          }

#header .container .main-menu .desktop-menu .menu-item:hover.has-child .icon-container {
                -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
              }

#header .container .main-menu .desktop-menu .menu-item:hover.has-child .icon-container [class^="icon-"],
                #header .container .main-menu .desktop-menu .menu-item:hover.has-child .icon-container [class*=" icon-"] {
                  opacity: 1;
                }

#header .container .main-menu .desktop-menu .menu-item:hover .sub-menu {
              z-index: 10;
              opacity: 1;
            }

#header .container .main-menu .desktop-menu .menu-item.has-child .icon-container {
              position: absolute;
              right: 0;
              transition: bottom 250ms ease-in-out, -webkit-transform 250ms ease-in-out;
              transition: bottom 250ms ease-in-out, transform 250ms ease-in-out;
              transition: bottom 250ms ease-in-out, transform 250ms ease-in-out, -webkit-transform 250ms ease-in-out;
            }

#header .container .main-menu .desktop-menu .menu-item.has-child .icon-container [class^="icon-"],
              #header .container .main-menu .desktop-menu .menu-item.has-child .icon-container [class*=" icon-"] {
                opacity: 0.5;
                transition: opacity 250ms ease-in-out;
              }

#header .container .main-menu .desktop-menu .menu-item.has-child .icon-container [class^="icon-"]:before, #header .container .main-menu .desktop-menu .menu-item.has-child .icon-container [class*=" icon-"]:before {
                  --text-opacity: 1;
                  color: #00AEf0;
                  color: rgba(0, 174, 240, var(--text-opacity));
                  font-size: 0.75rem;
                }

#header .container .main-menu .desktop-menu .menu-item a {
            font-weight: 600;
          }

/* &.active {
              &:before {
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 8px;
                content: "";
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                background-color: var(--reserva-clara);
              }
            } */

#header .container .main-menu .desktop-menu .menu-item a.primary-button {
              --text-opacity: 1;
              color: #ffffff;
              color: rgba(255, 255, 255, var(--text-opacity));
              font-size: 1rem;
            }

#header .container .main-menu .desktop-menu .menu-item a.primary-button:visited,
              #header .container .main-menu .desktop-menu .menu-item a.primary-button:active,
              #header .container .main-menu .desktop-menu .menu-item a.primary-button:focus {
                --text-opacity: 1;
                color: #ffffff;
                color: rgba(255, 255, 255, var(--text-opacity));
              }

#header .container .main-menu .desktop-menu .menu-item .sub-menu {
            position: absolute;
            padding: 1.25rem;
            transition-property: opacity;
            transition-duration: 150ms;
            opacity: 0;
            width: -webkit-max-content;
            width: max-content;
            /* margin-top: 28px; */
            top: 90%;
            box-shadow: 2px 2px 8px #1a435952;
            background-color: var(--reserva-blanco);
            border-radius: 4px;
            z-index: -10;
          }

#header .container .main-menu .desktop-menu .menu-item .sub-menu .sub-menu-item {
              padding-top: 0.25rem;
              padding-bottom: 0.25rem;
            }

#header .container .main-menu .desktop-menu .menu-item .sub-menu .sub-menu-item:hover a [class^="icon-"],
                  #header .container .main-menu .desktop-menu .menu-item .sub-menu .sub-menu-item:hover a [class*=" icon-"] {
                    opacity: 1;
                  }

#header .container .main-menu .desktop-menu .menu-item .sub-menu .sub-menu-item a {
                display: flex;
                align-items: center;
                justify-content: space-between;
                grid-gap: 3rem;
                gap: 3rem;
              }

#header .container .main-menu .desktop-menu .menu-item .sub-menu .sub-menu-item a [class^="icon-"],
                #header .container .main-menu .desktop-menu .menu-item .sub-menu .sub-menu-item a [class*=" icon-"] {
                  opacity: 0.5;
                  height: 1rem;
                  transition: opacity 0.25s ease-in-out;
                }

#header .container .main-menu .desktop-menu .menu-item .sub-menu .sub-menu-item a [class^="icon-"]:before, #header .container .main-menu .desktop-menu .menu-item .sub-menu .sub-menu-item a [class*=" icon-"]:before {
                    --text-opacity: 1;
                    color: #00AEf0;
                    color: rgba(0, 174, 240, var(--text-opacity));
                  }

#header .container .main-menu .desktop-menu .menu-item .primary-button {
            display: inline;
          }

#header .container .main-menu .mobile-menu {
        display: block;
        height: 100%;
      }

@media (min-width: 1280px) {

#header .container .main-menu .mobile-menu {
          display: none
      }
        }

#header .container .main-menu .mobile-menu #menu-toggle {
          position: relative;
          display: block;
          border-style: none;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          z-index: 1;
          -webkit-user-select: none;
          user-select: none;
        }

@media (min-width: 640px) {

#header .container .main-menu .mobile-menu #menu-toggle {
            left: 25px;

            right: 0
        }
          }

@media (min-width: 768px) {

#header .container .main-menu .mobile-menu #menu-toggle {
            left: 0px;

            right: 50px
        }
          }

@media (min-width: 1024px) {

#header .container .main-menu .mobile-menu #menu-toggle {
            left: 50px;

            right: 0
        }
          }

@media (min-width: 1280px) {

#header .container .main-menu .mobile-menu #menu-toggle {
            left: 0;

            right: 0
        }
          }

#header .container .main-menu .mobile-menu #menu-toggle:hover,
          #header .container .main-menu .mobile-menu #menu-toggle:active,
          #header .container .main-menu .mobile-menu #menu-toggle:focus {
            background-color: transparent;
            border-style: none;
          }

#header .container .main-menu .mobile-menu #menu-toggle input {
            display: block;
            width: 40px;
            height: 32px;
            position: absolute;
            top: -7px;
            left: -5px;
            cursor: pointer;
            opacity: 0;
            /* hide this */
            z-index: 2;
            /* and place it over the hamburger */
            -webkit-touch-callout: none;
          }

/* Transform all the slices of hamburger into a crossmark. */

#header .container .main-menu .mobile-menu #menu-toggle input:checked~span {
                opacity: 1;
                -webkit-transform: rotate(45deg) translate(-2px, -1px);
                transform: rotate(45deg) translate(-2px, -1px);
                background: #232323;
              }

/* But let's hide the middle one. */

#header .container .main-menu .mobile-menu #menu-toggle input:checked~span:nth-last-child(2) {
                opacity: 0;
                -webkit-transform: rotate(0deg) scale(0.2, 0.2);
                transform: rotate(0deg) scale(0.2, 0.2);
              }

/* Ohyeah and the last one should go the other direction */

#header .container .main-menu .mobile-menu #menu-toggle input:checked~span:nth-last-child(1) {
                -webkit-transform: rotate(-45deg) translate(0, -1px);
                transform: rotate(-45deg) translate(0, -1px);
              }

#header .container .main-menu .mobile-menu #menu-toggle span {
            --bg-opacity: 1;
            background-color: #264E72;
            background-color: rgba(38, 78, 114, var(--bg-opacity));
            display: block;
            width: 33px;
            height: 4px;
            margin-bottom: 5px;
            position: relative;
            border-radius: 3px;
            z-index: 1;
            -webkit-transform-origin: 4px 0px;
            transform-origin: 4px 0px;
            transition: background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
              opacity 0.55s ease,
              -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
            transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
              background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
              opacity 0.55s ease;
            transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
              background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
              opacity 0.55s ease,
              -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
          }

#header .container .main-menu .mobile-menu #menu-toggle span:first-child {
              -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
            }

#header .container .main-menu .mobile-menu #menu-toggle span:nth-last-child(1) {
              -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
            }

#header .container .main-menu .mobile-menu .menu {
          position: absolute;
          z-index: 50;
          width: 100vw;
          top: 112px;
          left: 50%;
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
          background-color: var(--reserva-blanco);
          max-height: 0px;
          transition: max-height 750ms ease-in-out;
          overflow-x: hidden;
        }

#header .container .main-menu .mobile-menu .menu.show {
            max-height: 100vh;
          }

#header .container .main-menu .mobile-menu .menu .menu-item {
            position: relative;
            padding-left: 3rem;
            padding-right: 3rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            margin-left: auto;
            margin-right: auto;
            color: var(--reserva-oscurisima);
            border-bottom: 1px solid rgba(var(--reserva-oscurisima-rgb), 0.08);
            font-size: 1rem;
          }

#header .container .main-menu .mobile-menu .menu .menu-item:nth-last-child(1),
            #header .container .main-menu .mobile-menu .menu .menu-item:nth-last-child(2) {
              border-bottom: none;
            }

#header .container .main-menu .mobile-menu .menu .menu-item:not(:last-child).has-child>button,
                #header .container .main-menu .mobile-menu .menu .menu-item:not(:last-child).has-child>a {
                  position: relative;
                  display: inline-block;
                  width: 100%;
                }

#header .container .main-menu .mobile-menu .menu .menu-item:not(:last-child).has-child>button .icon-container, #header .container .main-menu .mobile-menu .menu .menu-item:not(:last-child).has-child>a .icon-container {
                    position: absolute;
                    top: calc(50% - 6px);
                    -webkit-transform: rotate(-90deg) translateY(-50%);
                    transform: rotate(-90deg) translateY(-50%);
                    right: 0;
                    margin-top: 0px;
                    transition: margin 500ms ease-in-out,
                      -webkit-transform 500ms ease-in-out;
                    transition: transform 500ms ease-in-out,
                      margin 500ms ease-in-out;
                    transition: transform 500ms ease-in-out,
                      margin 500ms ease-in-out,
                      -webkit-transform 500ms ease-in-out;
                  }

#header .container .main-menu .mobile-menu .menu .menu-item:not(:last-child).has-child>button .icon-container [class^="icon-"],
                    #header .container .main-menu .mobile-menu .menu .menu-item:not(:last-child).has-child>button .icon-container [class*=" icon-"],
                    #header .container .main-menu .mobile-menu .menu .menu-item:not(:last-child).has-child>a .icon-container [class^="icon-"],
                    #header .container .main-menu .mobile-menu .menu .menu-item:not(:last-child).has-child>a .icon-container [class*=" icon-"] {
                      opacity: 0.5;
                      transition: opacity 500ms ease-in-out;
                    }

#header .container .main-menu .mobile-menu .menu .menu-item:last-child {
              width: 100%;
              padding-top: 0;
              padding-bottom: 0;
              margin-top: 2rem;
              margin-bottom: 2rem;
              text-align: center;
            }

#header .container .main-menu .mobile-menu .menu .menu-item:last-child a {
                width: 100%;
              }

#header .container .main-menu .mobile-menu .menu .menu-item.expand:not(:last-child).has-child>button .icon-container, #header .container .main-menu .mobile-menu .menu .menu-item.expand:not(:last-child).has-child>a .icon-container {
                      -webkit-transform: rotate(0deg) translateY(-50%);
                      transform: rotate(0deg) translateY(-50%);
                      margin-top: 4px;
                      margin-right: 8px;
                    }

#header .container .main-menu .mobile-menu .menu .menu-item.expand:not(:last-child).has-child>button .icon-container [class^="icon-"],
                      #header .container .main-menu .mobile-menu .menu .menu-item.expand:not(:last-child).has-child>button .icon-container [class*=" icon-"],
                      #header .container .main-menu .mobile-menu .menu .menu-item.expand:not(:last-child).has-child>a .icon-container [class^="icon-"],
                      #header .container .main-menu .mobile-menu .menu .menu-item.expand:not(:last-child).has-child>a .icon-container [class*=" icon-"] {
                        opacity: 1;
                      }

#header .container .main-menu .mobile-menu .menu .menu-item.expand .sub-menu {
                max-height: 100vh;
              }

#header .container .main-menu .mobile-menu .menu .menu-item.active:before {
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                height: 100%;
                width: 8px;
                content: "";
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                background-color: var(--reserva-clara);
              }

#header .container .main-menu .mobile-menu .menu .menu-item button,
            #header .container .main-menu .mobile-menu .menu .menu-item a {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              font-weight: 700;
              text-align: left;
              border-style: none;
            }

#header .container .main-menu .mobile-menu .menu .menu-item button:not(.primary-button):hover,
                #header .container .main-menu .mobile-menu .menu .menu-item button:not(.primary-button):active,
                #header .container .main-menu .mobile-menu .menu .menu-item button:not(.primary-button):focus,
                #header .container .main-menu .mobile-menu .menu .menu-item a:not(.primary-button):hover,
                #header .container .main-menu .mobile-menu .menu .menu-item a:not(.primary-button):active,
                #header .container .main-menu .mobile-menu .menu .menu-item a:not(.primary-button):focus {
                  background-color: transparent;
                  border-style: none;
                  outline: 2px solid transparent;
                  outline-offset: 2px;
                }

#header .container .main-menu .mobile-menu .menu .menu-item button.primary-button, #header .container .main-menu .mobile-menu .menu .menu-item a.primary-button {
                justify-content: center;
                text-align: center;
              }

#header .container .main-menu .mobile-menu .menu .menu-item .sub-menu {
              max-height: 0;
              overflow: hidden;
              transition: max-height 750ms ease-in-out;
            }

#header .container .main-menu .mobile-menu .menu .menu-item .sub-menu .sub-menu-item {
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                margin-left: 1rem;
                color: var(--reserva-oscurisima);
              }

#header .container .main-menu .mobile-menu .menu .menu-item .sub-menu .sub-menu-item:first-child {
                  padding-top: 1.5rem;
                }

#header .container .main-menu .mobile-menu .menu .menu-item .sub-menu .sub-menu-item:last-child {
                  padding-bottom: 1.5rem;
                }

#header .container .main-menu .mobile-menu .menu .menu-item .sub-menu .sub-menu-item a {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                }

#header .container .main-menu .mobile-menu .menu .menu-item .sub-menu .sub-menu-item a [class^="icon-"],
                  #header .container .main-menu .mobile-menu .menu .menu-item .sub-menu .sub-menu-item a [class*=" icon-"] {
                    opacity: 0.5;
                    height: 1rem;
                  }

#header .container .main-menu .mobile-menu .menu .menu-item .sub-menu .sub-menu-item a [class^="icon-"]:before, #header .container .main-menu .mobile-menu .menu .menu-item .sub-menu .sub-menu-item a [class*=" icon-"]:before {
                      --text-opacity: 1;
                      color: #00AEf0;
                      color: rgba(0, 174, 240, var(--text-opacity));
                    }