[data-v-fa3f5814]:root {
  --sm-breakpoint: 640px;
  --md-breakpoint: 768px;
  --lg-breakpoint: 1024px;
  --xl-breakpoint: 1280px;
  --xxl-breakpoint: 1600px;
}
.filter[data-v-fa3f5814] {
  /* .date-published {
    @apply relative;

    label {
      @apply py-3 pr-8 pl-3 text-oscura;
      border: 2px solid rgba(var(--reserva-oscura-rgb), 0.24);
      border-radius: 4px;

      span {
        @apply mr-3;
      }
    }
    input {
      @apply absolute w-0 h-0 opacity-0;
      bottom: 0;
      left: 0;
    }
  } */
}
.filter .datepicker-container[data-v-fa3f5814] {
    position: relative;
    display: inline-block;
    margin-top: 0;
    flex: 1 1 100%;
    font-size: 1.25rem;
}
@media (min-width: 640px) {
.filter .datepicker-container[data-v-fa3f5814] {
      margin-top: 1.5rem;

      flex: 0 1 auto
}
}
@media (min-width: 1280px) {
.filter .datepicker-container[data-v-fa3f5814] {
      margin-top: 0
}
}
@media (min-width: 640px) {
.filter .datepicker-container:not(:last-child) .datepicker[data-v-fa3f5814]:after {
            margin-left: 1rem;
            content: "|";
            font-size: 1.5rem;
            color: rgba(var(--reserva-oscura-rgb), 0.24);
}
}
.filter .datepicker-container .datepicker[data-v-fa3f5814] {
      display: flex;
      align-items: center;
}
.filter .datepicker-container .datepicker #year-field[data-v-fa3f5814] {
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        font-size: 1.25rem;
}
.filter .datepicker-container .datepicker input[data-v-fa3f5814] {
        width: 0;
}
.filter .datepicker-container .datepicker input[data-v-fa3f5814]:focus {
          outline: none;
}
.filter .datepicker-container .datepicker label[data-v-fa3f5814] {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        flex: 1 1 100%;
        border: 2px solid rgba(var(--reserva-oscura-rgb), 0.24);
        border-radius: 4px;
}
.filter .datepicker-container .datepicker label i[data-v-fa3f5814],
        .filter .datepicker-container .datepicker label [class^="icon-"][data-v-fa3f5814],
        .filter .datepicker-container .datepicker label [class*=" icon-"][data-v-fa3f5814] {
          margin-right: 0.75rem;
}
.filter .datepicker-container .datepicker label .selected-year[data-v-fa3f5814] {
          font-weight: 700;
}
.news-container[data-v-fa3f5814] {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-gap: 2.5rem;
  gap: 2.5rem;
  margin-top: 3rem;
}
@media (min-width: 1280px) {
.news-container[data-v-fa3f5814] {
    grid-template-columns: repeat(2, minmax(0, 1fr))
}
}
@media (min-width: 1280px) {
.news-container .pagination-container[data-v-fa3f5814] {
      grid-column: span 2 / span 2
}
}
