#cifras > .container[data-v-565708cc] {
    margin-top: 4rem;
    margin-left: auto;
    margin-right: auto;
}
#cifras > .container .fondo[data-v-565708cc] {
      padding: 2rem;
      background-image: linear-gradient(
        to right,
        rgba(var(--reserva-oscura-rgb), 0.08),
        rgba(var(--reserva-oscura-rgb), 0.08)
      );
      background-position: 0 0;
      background-size: 100% 85%;
      background-repeat: no-repeat;
      border-radius: 4px;
}
@media (min-width: 768px) {
#cifras > .container .fondo[data-v-565708cc] {
        background-size: 100% 80%
}
}
@media (min-width: 1280px) {
#cifras > .container .fondo[data-v-565708cc] {
        background-size: 100% 70%
}
}
#cifras > .container .fondo[data-v-565708cc]:not(:first-child) {
        margin-top: 4rem;
}
#cifras > .container .fondo[data-v-565708cc]:not(:last-child) {
        margin-bottom: 4rem;
}
#cifras > .container .fondo h2[data-v-565708cc] {
        text-align: center;
        margin-bottom: 2rem;
}
#cifras > .container .fondo .info-container[data-v-565708cc] {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        grid-gap: 2rem;
        gap: 2rem;
}
#cifras > .container .fondo .info-container .info[data-v-565708cc] {
          padding-top: 2.5rem;
          padding-bottom: 2.5rem;
          text-align: center;
          background-color: var(--reserva-blanco);
          box-shadow: 2px 2px 16px #1a43591a;
          border-radius: 4px;
          min-width: 100%;
}
@media (min-width: 768px) {
#cifras > .container .fondo .info-container .info[data-v-565708cc] {
            min-width: 75%
}
}
@media (min-width: 1024px) {
#cifras > .container .fondo .info-container .info[data-v-565708cc] {
            min-width: 45%
}
}
@media (min-width: 1280px) {
#cifras > .container .fondo .info-container .info[data-v-565708cc] {
            min-width: 31%
}
}
#cifras > .container .fondo .info-container .info h3[data-v-565708cc] {
            color: var(--reserva-oscura);
}
@media (max-width: 639px) {
#cifras > .container .fondo .info-container .info h3[data-v-565708cc] {
              font-size: 1rem
}
}
#cifras > .container .fondo .info-container .info p[data-v-565708cc] {
            font-weight: bold;
            text-transform: uppercase;
}
@media (max-width: 639px) {
#cifras > .container .fondo .info-container .info p[data-v-565708cc] {
              font-size: 0.75rem
}
}
