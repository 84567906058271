[data-v-c2487680]:root {
  --sm-breakpoint: 640px;
  --md-breakpoint: 768px;
  --lg-breakpoint: 1024px;
  --xl-breakpoint: 1280px;
  --xxl-breakpoint: 1600px;
}
.filter[data-v-c2487680] {
  margin-bottom: 0.5rem;
}
@media (min-width: 1024px) {
.filter[data-v-c2487680] {
    margin-bottom: 1rem
}
}
.filter .desktop-filter[data-v-c2487680] {
    overflow-x: auto;
    white-space: nowrap;
    padding-bottom: 1rem;
}
@media (max-width: 767px) {
.filter .desktop-filter[data-v-c2487680] {
      display: none
}
}
.filter .desktop-filter li[data-v-c2487680] {
      display: inline-block;
}
.filter .desktop-filter li[data-v-c2487680]:not(:last-child) {
        margin-right: 1rem;
}
.filter .desktop-filter li.active button[data-v-c2487680] {
          --text-opacity: 1;
          color: #ffffff;
          color: rgba(255, 255, 255, var(--text-opacity));
          --bg-opacity: 1;
          background-color: #264E72;
          background-color: rgba(38, 78, 114, var(--bg-opacity));
}
.filter .desktop-filter li button[data-v-c2487680] {
        display: flex;
        align-items: center;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        --text-opacity: 1;
        color: #264E72;
        color: rgba(38, 78, 114, var(--text-opacity));
        font-weight: 600;
        border-radius: 4px;
        background-color: rgba(var(--reserva-oscura-rgb), 0.08);
}
.filter .desktop-filter li button [class^="icon-"][data-v-c2487680]:before, .filter .desktop-filter li button [class*=" icon-"][data-v-c2487680]:before {
            --text-opacity: 1;
            color: #264E72;
            color: rgba(38, 78, 114, var(--text-opacity));
            margin-right: 0.75rem;
            margin-top: 4px;
}
.filter .mobile-filter[data-v-c2487680] {
    width: 100%;
    margin-bottom: 1.5rem;
}
@media (min-width: 768px) {
.filter .mobile-filter[data-v-c2487680] {
      display: none
}
}
.pagination-container[data-v-c2487680] {
  margin-top: 1rem;
}
@media (min-width: 1024px) {
.pagination-container[data-v-c2487680] {
    margin-top: 1.5rem
}
}
