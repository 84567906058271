[data-v-5f186e6c]:root {
  --sm-breakpoint: 640px;
  --md-breakpoint: 768px;
  --lg-breakpoint: 1024px;
  --xl-breakpoint: 1280px;
  --xxl-breakpoint: 1600px;
}
#beneficios .container[data-v-5f186e6c] {
    margin-left: auto;
    margin-right: auto;
}
#beneficios .container .intro[data-v-5f186e6c] {
      margin-top: 4rem;
      margin-bottom: 4rem;
}
#beneficios .container .intro p[data-v-5f186e6c] {
        --text-opacity: 1;
        color: #264E72;
        color: rgba(38, 78, 114, var(--text-opacity));
        font-size: 1.5rem;
}
#beneficios .container .beneficios[data-v-5f186e6c] {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      grid-gap: 2.5rem;
      gap: 2.5rem;
}
@media (min-width: 768px) {
#beneficios .container .beneficios[data-v-5f186e6c] {
        grid-template-columns: repeat(2, minmax(0, 1fr))
}
}
@media (min-width: 1024px) {
#beneficios .container .beneficios[data-v-5f186e6c] {
        grid-template-columns: repeat(3, minmax(0, 1fr))
}
}
