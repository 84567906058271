:root {
  --sm-breakpoint: 640px;
  --md-breakpoint: 768px;
  --lg-breakpoint: 1024px;
  --xl-breakpoint: 1280px;
  --xxl-breakpoint: 1600px;
}

#signup-bar {
  background-color: var(--reserva-clara);
}

#signup-bar .container {
    display: flex;
    flex-wrap: wrap;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    margin-left: auto;
    margin-right: auto;
    align-content: center;
    align-items: center;
    justify-content: center;
    grid-column-gap: 40px;
    -webkit-column-gap: 40px;
    column-gap: 40px;
  }

@media (min-width: 1024px) {

#signup-bar .container {
      flex-wrap: nowrap
  }
    }

#signup-bar .container p {
      color: var(--reserva-blanco);
      font-size: 1rem;
    }

@media (min-width: 768px) {

#signup-bar .container p {
        font-size: 1.25rem
    }
      }

@media (min-width: 1024px) {

#signup-bar .container p {
        font-size: 1.5rem
    }
      }

#signup-bar .container a {
      width: 100%;
      padding-left: 4rem;
      padding-right: 4rem;
      text-align: center;
      flex-basis: 840px;
      font-size: 1.25rem;
      background-color: var(--reserva-oscura);
    }

@media (min-width: 768px) {

#signup-bar .container a {
        width: auto;

        font-size: 1.5rem
    }
      }

@media (min-width: 1024px){

#signup-bar .container a {
        font-size: 2rem
    }
      }

#news-highlights {
  padding-top: 2rem;
  padding-bottom: 4rem;
  background-color: var(--reserva-gris);
}

@media (min-width: 1024px) {

#news-highlights {
    padding-top: 4rem;

    padding-bottom: 8rem
}
  }

#news-highlights .link-container {
    display: flex;
    justify-content: center;
    align-content: center;
  }

#news-highlights .link-container .primary-button-outline {
      width: 100%;
      text-align: center;
      margin-top: 3rem;
      font-weight: 700;
      --bg-opacity: 1;
      background-color: #ffffff;
      background-color: rgba(255, 255, 255, var(--bg-opacity));
    }

@media (min-width: 640px) {

#news-highlights .link-container .primary-button-outline {
        width: auto;

        padding-left: 12rem;

        padding-right: 12rem
    }
      }

#app-download {
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: var(--reserva-blanco);
}

@media (min-width: 1024px) {

#app-download {
    padding-top: 8rem;

    padding-bottom: 4rem
}
  }

#app-download .container {
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

@media (min-width: 1024px) {

#app-download .container {
      grid-template-columns: repeat(2, minmax(0, 1fr))
  }
    }

#app-download .container .celulares {
      margin: auto;
      max-width: 100%;
    }

@media (--sm-md-screen) {

#app-download .container .celulares {
        max-width: 550px
    }
      }

#app-download .container h1 {
      margin-bottom: 2rem;
    }

#app-download .container .section-desc {
      margin-top: 2rem;
      margin-bottom: 4rem;
      --text-opacity: 1;
      color: #264E72;
      color: rgba(38, 78, 114, var(--text-opacity));
    }

@media (min-width: 1280px) {

#app-download .container .app-info {
        padding-left: 6rem;

        padding-right: 6rem
    }
      }

#app-download .container .app-info .download-links {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 1rem;
        gap: 1rem;
      }

@media (max-width: 1023px) {

#app-download .container .app-info .download-links {
          grid-template-columns: repeat(1, minmax(0, 1fr))
      }
        }

#app-download .container .app-info .download-links .app-links {
          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          grid-gap: 0.5rem;
          gap: 0.5rem;
          justify-content: space-between;
          align-content: center;
          justify-items: center;
        }

@media (min-width: 1024px) {

#app-download .container .app-info .download-links .app-links {
            grid-template-columns: repeat(1, minmax(0, 1fr))
        }
          }

#app-download .container .app-info .download-links .app-links a {
            display: inline-block;
          }

#app-download .container .app-info .download-links .qr-code {
          display: none;
        }

@media (min-width: 1024px) {

#app-download .container .app-info .download-links .qr-code {
            display: inline-block
        }
          }

#app-download .container .app-info .download-links .qr-code img {
            margin-left: auto;
            margin-right: auto;
          }

#satisfaction-notice {
  padding-top: 5rem;
  padding-bottom: 5rem;
  width: 100%;
  background-color: var(--reserva-gris);
}

#satisfaction-notice .container {
    margin-left: auto;
    margin-right: auto;
  }

#satisfaction-notice .container h2 {
      text-align: center;
      color: var(--reserva-oscura);
    }

#appointment-link {
  padding-top: 4rem;
  background-color: var(--reserva-blanco);
}

#appointment-link .container {
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

@media (min-width: 1024px) {

#appointment-link .container {
      grid-template-columns: repeat(2, minmax(0, 1fr))
  }
    }

#appointment-link .container .appointment-info {
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      margin-right: 8rem;
    }

#appointment-link .container .appointment-info h1 {
        text-align: center;
        padding-bottom: 2rem;
      }

#appointment-link .container .appointment-info p {
        padding-bottom: 2.5rem;
        --text-opacity: 1;
        color: #264E72;
        color: rgba(38, 78, 114, var(--text-opacity));
      }

#appointment-link .container .appointment-info a {
        width: 100%;
        text-align: center;
      }

#appointment-link .container .map-container .oficinas-nav {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        padding-left: 1rem;
        padding-right: 1rem;
        width: 95%;
        margin: 0 auto -45px auto;
        z-index: 2;
        background: white;
        border-radius: 4px;
        box-shadow: 2px 2px 44px #1a435929;
      }

@media (min-width: 640px) {

#appointment-link .container .map-container .oficinas-nav {
          width: 70%
      }
        }

#appointment-link .container .map-container .oficinas-nav .splide__arrow {
          background-color: transparent;
        }

#appointment-link .container .map-container .oficinas-nav .splide__arrow i,
          #appointment-link .container .map-container .oficinas-nav .splide__arrow [class^="icon-"],
          #appointment-link .container .map-container .oficinas-nav .splide__arrow [class*=" icon-"] {
            opacity: 0.6;
            transition: opacity 0.25s ease-in-out;
          }

#appointment-link .container .map-container .oficinas-nav .splide__arrow i:before, #appointment-link .container .map-container .oficinas-nav .splide__arrow [class^="icon-"]:before, #appointment-link .container .map-container .oficinas-nav .splide__arrow [class*=" icon-"]:before {
              --text-opacity: 1;
              color: #264E72;
              color: rgba(38, 78, 114, var(--text-opacity));
            }

#appointment-link .container .map-container .oficinas-nav .splide__arrow i:hover, #appointment-link .container .map-container .oficinas-nav .splide__arrow [class^="icon-"]:hover, #appointment-link .container .map-container .oficinas-nav .splide__arrow [class*=" icon-"]:hover {
              opacity: 1;
            }

#appointment-link .container .map-container .oficinas-nav .splide__track {
          width: 100%;
        }

#appointment-link .container .map-container .oficinas-nav .splide__track .splide__slide {
            text-align: center;
          }

#appointment-link .container .map-container .oficinas-nav .splide__track .splide__slide strong {
              --text-opacity: 1;
              color: #264E72;
              color: rgba(38, 78, 114, var(--text-opacity));
            }

@media (min-width: 640px) {

#appointment-link .container .map-container .oficinas-nav .splide__track .splide__slide strong {
                font-size: 1.25rem
            }
              }

#appointment-link .container .map-container .oficinas-nav .splide__pagination {
          display: flex;
          justify-content: center;
          grid-gap: 0.75rem;
          gap: 0.75rem;
          margin-top: 0.75rem;
        }

#appointment-link .container .map-container .oficinas-nav .splide__pagination li .splide__pagination__page {
              width: 12px;
              height: 12px;
              background-color: rgba(var(--reserva-oscura-rgb), 0.6);
              border-radius: 100%;
            }

#appointment-link .container .map-container .oficinas-nav .splide__pagination li .splide__pagination__page.is-active {
                background-color: var(--reserva-clara);
              }

#appointment-link .container .map-container .splide .splide__track .splide__list .splide__slide {
              padding-bottom: 2rem;
            }

@media (max-width: 767px) {

#appointment-link .container .map-container .splide .splide__track .splide__list .splide__slide .desktop-image {
                  display: none
              }
                }

#appointment-link .container .map-container .splide .splide__track .splide__list .splide__slide .mobile-image {
                object-fit: cover;
                max-height: 466px;
              }

@media (min-width: 768px) {

#appointment-link .container .map-container .splide .splide__track .splide__list .splide__slide .mobile-image {
                  display: none
              }
                }

#appointment-link .container .map-container .splide .splide__track .splide__list .splide__slide .direccion {
                position: relative;
                padding: 2rem;
                --text-opacity: 1;
                color: #264E72;
                color: rgba(38, 78, 114, var(--text-opacity));
                width: 95%;
                background: white;
                margin: -8rem auto 0 auto;
                z-index: 2;
                border-radius: 4px;
                box-shadow: 2px 2px 44px #1a435929;
              }

@media (min-width: 640px) {

#appointment-link .container .map-container .splide .splide__track .splide__list .splide__slide .direccion {
                  width: 70%
              }
                }

#appointment-link .container .map-container .splide .splide__track .splide__list .splide__slide .direccion .section:not(:last-child) {
                    margin-bottom: 2rem;
                  }

#appointment-link .container .map-container .splide .splide__track .splide__list .splide__slide .direccion .section strong {
                    display: block;
                    margin-bottom: 1rem;
                    font-size: 1.25rem;
                  }

#appointment-link .container .map-container .splide .splide__track .splide__list .splide__slide .direccion .section .detail {
                    display: flex;
                  }

#appointment-link .container .map-container .splide .splide__track .splide__list .splide__slide .direccion .section .detail:not(:last-child) {
                      margin-bottom: 1rem;
                    }

#appointment-link .container .map-container .splide .splide__track .splide__list .splide__slide .direccion .section .detail i,
                    #appointment-link .container .map-container .splide .splide__track .splide__list .splide__slide .direccion .section .detail [class^="icon-"],
                    #appointment-link .container .map-container .splide .splide__track .splide__list .splide__slide .direccion .section .detail [class*=" icon-"] {
                      margin-right: 0.75rem;
                      margin-top: 6px;
                    }

#appointment-link .container .map-container .splide .splide__track .splide__list .splide__slide .direccion .section .detail i:before, #appointment-link .container .map-container .splide .splide__track .splide__list .splide__slide .direccion .section .detail [class^="icon-"]:before, #appointment-link .container .map-container .splide .splide__track .splide__list .splide__slide .direccion .section .detail [class*=" icon-"]:before {
                        --text-opacity: 1;
                        color: #264E72;
                        color: rgba(38, 78, 114, var(--text-opacity));
                      }

#appointment-link .container img {
      width: 100%;
    }

#appointment-link .container .mobile-item p {
        margin-top: 1rem;
        padding-bottom: 1.5rem;
      }

#appointment-link .container .mobile-item a {
        margin-top: 1rem;
        width: 100%;
        text-align: center;
      }
