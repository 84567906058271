:root {
  --reserva-oscurisima: #1b3a57;
  --reserva-oscurisima-rgb: 27, 58, 87;
  --reserva-oscura: #264e72;
  --reserva-oscura-rgb: 38, 78, 114;
  --reserva-clara: #00aef0;
  --reserva-clara-rgb: 0, 174, 240;
  --reserva-naranja: #f89420;
  --reserva-naranja-rgb: 248, 148, 32;
  --reserva-verde: #a9b737;
  --reserva-grafito: #626266;
  --reserva-grafito-rgb: 98, 98, 102;
  --reserva-plata: #b1b3b5;
  --reserva-plata-rgb: 177, 179, 181;
  --reserva-blanco: #ffffff;
  --reserva-blanco-rgb: 255, 255, 255;
  --reserva-gris: #f1f3f6;
  --reserva-gris-rgb: 241, 243, 246;
  --reserva-negro: #000000;
  --reserva-negro-rgb: 0, 0, 0;
}

:root {
  --sm-breakpoint: 640px;
  --md-breakpoint: 768px;
  --lg-breakpoint: 1024px;
  --xl-breakpoint: 1280px;
  --xxl-breakpoint: 1600px;
}

.container {
  margin-left: auto;
  margin-right: auto;
  transition: padding 0.5s ease-in-out;
}

@media (max-width: 639px) {

.container {
    padding-left: 1rem;

    padding-right: 1rem
}
  }

@media (min-width: 640px) and (max-width: calc(640px + 48px)) {

.container {
    padding-left: 3rem;

    padding-right: 3rem
}
  }

@media (min-width: 768px) and (max-width: calc(768px + 48px)) {

.container {
    padding-left: 3rem;

    padding-right: 3rem
}
  }

@media (min-width: 1024px) and (max-width: calc(1024px + 48px)) {

.container {
    padding-left: 3rem;

    padding-right: 3rem
}
  }

@media (min-width: 1280px) and (max-width: calc(1280px + 48px)) {

.container {
    padding-left: 3rem;

    padding-right: 3rem
}
  }

@media (min-width: 1600px) {
  /* Agregando soporte para resoluciones 1080 */

  body .container {
    max-width: 1472px;
  }
}

/* Clases globales para agregar funcionalidades básicas responsive */

@media (max-width: 1023px) {

.desktop-item {
    display: none !important
}
  }

@media (min-width: 1024px) {

.mobile-item {
    display: none !important
}
  }

html {
  font-size: 100%;
}

html * {
    font-family: var(--reserva-font);
  }

/* TEXT */

html p,
  html .ms-rtestate-field p {
    --text-opacity: 1;
    color: #264E72;
    color: rgba(38, 78, 114, var(--text-opacity));
    font-size: 1rem;
  }

html p.big-text, html .ms-rtestate-field p.big-text {
      font-size: 1rem;
    }

@media (min-width: 768px) {

html p.big-text, html .ms-rtestate-field p.big-text {
        font-size: 1.25rem
    }
      }

html a,
  html .ms-rtestate-field a {
    --text-opacity: 1;
    color: #264E72;
    color: rgba(38, 78, 114, var(--text-opacity));
    font-size: 1rem;
  }

html a.big-text, html .ms-rtestate-field a.big-text {
      font-size: 1rem;
    }

@media (min-width: 768px) {

html a.big-text, html .ms-rtestate-field a.big-text {
        font-size: 1.25rem
    }
      }

/* TITLES */

html h1,
  html .ms-rtestate-field h1 {
    font-weight: 700;
    --text-opacity: 1;
    color: #00AEf0;
    color: rgba(0, 174, 240, var(--text-opacity));
    font-size: 2rem;
  }

@media (min-width: 768px) {

html h1,
  html .ms-rtestate-field h1 {
      font-size: 3rem
  }
    }

@media (min-width: 1024px) {

html h1,
  html .ms-rtestate-field h1 {
      font-size: 4rem
  }
    }

html h2,
  html .ms-rtestate-field h2 {
    font-weight: 700;
    --text-opacity: 1;
    color: #00AEf0;
    color: rgba(0, 174, 240, var(--text-opacity));
    font-size: 1.5rem;
  }

@media (min-width: 768px) {

html h2,
  html .ms-rtestate-field h2 {
      font-size: 2rem
  }
    }

@media (min-width: 1024px) {

html h2,
  html .ms-rtestate-field h2 {
      font-size: 2.5rem
  }
    }

html h3,
  html .ms-rtestate-field h3 {
    font-weight: 700;
    --text-opacity: 1;
    color: #00AEf0;
    color: rgba(0, 174, 240, var(--text-opacity));
    font-size: 1.5rem;
  }

@media (min-width: 768px) {

html h3,
  html .ms-rtestate-field h3 {
      font-size: 1.75rem
  }
    }

@media (min-width: 1024px) {

html h3,
  html .ms-rtestate-field h3 {
      font-size: 2rem
  }
    }

html h4,
  html .ms-rtestate-field h4 {
    font-weight: 700;
    --text-opacity: 1;
    color: #264E72;
    color: rgba(38, 78, 114, var(--text-opacity));
    font-size: 1rem;
  }

@media (min-width: 768px) {

html h4,
  html .ms-rtestate-field h4 {
      font-size: 1.25rem
  }
    }

@media (min-width: 1024px) {

html h4,
  html .ms-rtestate-field h4 {
      font-size: 1.5rem
  }
    }

/* FORM ITEMS */

html select .ms-rtestate-field select {
    --text-opacity: 1;
    color: #264E72;
    color: rgba(38, 78, 114, var(--text-opacity));
    font-size: 1rem;
  }

@-webkit-keyframes SlideInFromLeft {
  0% {
    max-width: 0px;
  }

  100% {
    max-width: 100vw;
  }
}

@keyframes SlideInFromLeft {
  0% {
    max-width: 0px;
  }

  100% {
    max-width: 100vw;
  }
}

@-webkit-keyframes SlideInFromBottom {
  0% {
    max-height: 0px;
  }

  100% {
    max-height: 100vh;
  }
}

@keyframes SlideInFromBottom {
  0% {
    max-height: 0px;
  }

  100% {
    max-height: 100vh;
  }
}

.responsive-grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 2.5rem;
    gap: 2.5rem;
  }

@media (min-width: 768px) {

.responsive-grid {
      grid-template-columns: repeat(2, minmax(0, 1fr))
  }
    }

@media (min-width: 1024px) {

.responsive-grid {
      grid-template-columns: repeat(3, minmax(0, 1fr))
  }
    }

@media (min-width: 1280px) {

.responsive-grid {
      grid-template-columns: repeat(4, minmax(0, 1fr))
  }
    }

#services #page-header {
    margin-bottom: 4rem;
  }

#services .services-list {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 2.5rem;
    gap: 2.5rem;
  }

@media (min-width: 768px) {

#services .services-list {
      grid-template-columns: repeat(2, minmax(0, 1fr))
  }
    }

@media (min-width: 1280px) {

#services .services-list {
      grid-template-columns: repeat(3, minmax(0, 1fr))
  }
    }

#services .services-list .service {
      --bg-opacity: 1;
      background-color: #ffffff;
      background-color: rgba(255, 255, 255, var(--bg-opacity));
      padding: 1rem;
      box-shadow: 2px 2px 16px #1a43591a;
    }

@media (min-width: 768px) {

#services .services-list .service {
        padding: 2rem
    }
      }

#services .services-list .service .icon {
        display: inline-block;
        margin-bottom: 2.5rem;
      }

#services .services-list .service .icon:before {
          --text-opacity: 1;
          color: #00AEf0;
          color: rgba(0, 174, 240, var(--text-opacity));
          font-size: 64px;
        }

#services .services-list .service h5 {
        margin-bottom: 1rem;
        --text-opacity: 1;
        color: #264E72;
        color: rgba(38, 78, 114, var(--text-opacity));
        font-weight: 700;
        font-size: 1.25rem;
      }

@media (min-width: 1024px) {

#services .services-list .service h5 {
          font-size: 1.5rem
      }
        }

#services .services-list .service p {
        --text-opacity: 1;
        color: #264E72;
        color: rgba(38, 78, 114, var(--text-opacity));
      }

@media (min-width: 1024px) {

#services .services-list .service p {
          font-size: 1.25rem
      }
        }
