:root {
  --sm-breakpoint: 640px;
  --md-breakpoint: 768px;
  --lg-breakpoint: 1024px;
  --xl-breakpoint: 1280px;
  --xxl-breakpoint: 1600px;
}

#footer {
  padding-top: 3rem;
  margin-top: 5rem;
  --bg-opacity: 1;
  background-color: #264E72;
  background-color: rgba(38, 78, 114, var(--bg-opacity));
}

#footer .container {
    margin-left: auto;
    margin-right: auto;
  }

#footer .container h4 {
      --text-opacity: 1;
      color: #00AEf0;
      color: rgba(0, 174, 240, var(--text-opacity));
      margin-bottom: 0.5rem;
    }

#footer .container h5 {
      --text-opacity: 1;
      color: #00AEf0;
      color: rgba(0, 174, 240, var(--text-opacity));
      font-weight: 700;
      font-size: 1rem;
    }

#footer .container .nav-container {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      grid-gap: 2.5rem;
      gap: 2.5rem;
    }

@media (min-width: 1024px) {

#footer .container .nav-container {
        grid-template-columns: repeat(3, minmax(0, 1fr));

        grid-gap: 10rem;

        gap: 10rem
    }
      }

#footer .container .nav-container .contact-info .section {
          margin-bottom: 2rem;
        }

#footer .container .nav-container .contact-info .section a {
            --text-opacity: 1;
            color: #ffffff;
            color: rgba(255, 255, 255, var(--text-opacity));
          }

#footer .container .nav-container .contact-info .section .primary-button-outline {
            width: 100%;
            text-align: center;
            --text-opacity: 1;
            color: #fff;
            color: rgba(255, 255, 255, var(--text-opacity));
            font-weight: 400;
            margin-top: 0.75rem;
            font-size: 1rem;
          }

#footer .container .nav-container .contact-info .social-media {
          margin-bottom: 2.5rem;
        }

#footer .container .nav-container .contact-info .social-media li {
            display: inline-block;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
          }

#footer .container .nav-container .contact-info .social-media li a i,
              #footer .container .nav-container .contact-info .social-media li a [class^="icon-"],
              #footer .container .nav-container .contact-info .social-media li a [class*=" icon-"] {
                --text-opacity: 1;
                color: #ffffff;
                color: rgba(255, 255, 255, var(--text-opacity));
                font-size: 24px;
              }

#footer .container .nav-container .contact-info .locations i:before, #footer .container .nav-container .contact-info .locations [class^="icon-"]:before, #footer .container .nav-container .contact-info .locations [class*=" icon-"]:before {
              --text-opacity: 1;
              color: #ffffff;
              color: rgba(255, 255, 255, var(--text-opacity));
              font-size: 1rem;
            }

#footer .container .nav-container .contact-info .detail {
          display: flex;
          margin-bottom: 1rem;
        }

#footer .container .nav-container .contact-info .detail i,
          #footer .container .nav-container .contact-info .detail [class^="icon-"],
          #footer .container .nav-container .contact-info .detail [class*=" icon-"] {
            margin-right: 0.75rem;
            margin-top: 6px;
            height: -webkit-fit-content;
            height: -moz-fit-content;
            height: fit-content;
          }

#footer .container .nav-container .contact-info .detail p {
            --text-opacity: 1;
            color: #ffffff;
            color: rgba(255, 255, 255, var(--text-opacity));
          }

#footer .container .nav-container .desktop-site-map {
        grid-column: span 2 / span 2;
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-row-gap: 2rem;
        row-gap: 2rem;
        grid-column-gap: 3rem;
        -webkit-column-gap: 3rem;
        column-gap: 3rem;
      }

@media (min-width: 1024px) {

#footer .container .nav-container .desktop-site-map {
          grid-template-columns: repeat(2, minmax(0, 1fr))
      }
        }

@media (min-width: 1280px) {

#footer .container .nav-container .desktop-site-map {
          grid-template-columns: repeat(3, minmax(0, 1fr))
      }
        }

@media (min-width: 1600px) {

#footer .container .nav-container .desktop-site-map {
          grid-template-columns: repeat(4, minmax(0, 1fr))
      }
        }

#footer .container .nav-container .desktop-site-map .menu-item a {
            --text-opacity: 1;
            color: #ffffff;
            color: rgba(255, 255, 255, var(--text-opacity));
          }

#footer .container .nav-container .desktop-site-map .menu-item ul .sub-menu-item {
              padding-top: 0.5rem;
              padding-bottom: 0.5rem;
            }

#footer .container .nav-container .desktop-site-map .menu-item ul .sub-menu-item a {
                --text-opacity: 1;
                color: #ffffff;
                color: rgba(255, 255, 255, var(--text-opacity));
              }

#footer .container .nav-container .desktop-site-map .menu-item ul .sub-menu-item a [class^="icon-"],
                #footer .container .nav-container .desktop-site-map .menu-item ul .sub-menu-item a [class*=" icon-"] {
                  margin-left: 0.75rem;
                }

#footer .container .nav-container .mobile-site-map .tab:not(:last-child) {
            margin-bottom: 0.75rem;
          }

#footer .container .nav-container .mobile-site-map .tab .tab-label {
            position: relative;
            display: inline-block;
            padding: 0.5rem;
            width: 100%;
            font-weight: 700;
            --text-opacity: 1;
            color: #00AEf0;
            color: rgba(0, 174, 240, var(--text-opacity));
            background-color: rgba(var(--reserva-blanco-rgb), 0.04);
            border-radius: 4px;
            font-size: 1rem;
          }

#footer .container .nav-container .mobile-site-map .tab .tab-label .icon-container {
              position: absolute;
              display: inline-block;
              right: 10px;
              top: calc(50% - 8px);
              transition: -webkit-transform 250ms ease-in-out;
              transition: transform 250ms ease-in-out;
              transition: transform 250ms ease-in-out, -webkit-transform 250ms ease-in-out;
            }

#footer .container .nav-container .mobile-site-map .tab .tab-label .icon-container [class^="icon-"],
              #footer .container .nav-container .mobile-site-map .tab .tab-label .icon-container [class*=" icon-"] {
                opacity: 0.5;
                font-size: 1rem;
                transition: opacity 250ms ease-in-out;
              }

#footer .container .nav-container .mobile-site-map .tab .tab-label .icon-container [class^="icon-"]:before, #footer .container .nav-container .mobile-site-map .tab .tab-label .icon-container [class*=" icon-"]:before {
                  --text-opacity: 1;
                  color: #ffffff;
                  color: rgba(255, 255, 255, var(--text-opacity));
                }

#footer .container .nav-container .mobile-site-map .tab .tab-check {
            position: absolute;
            opacity: 0;
            z-index: -1;
          }

#footer .container .nav-container .mobile-site-map .tab .tab-check:checked ~ .tab-label .icon-container {
                  -webkit-transform: rotate(90deg);
                  transform: rotate(90deg);
                }

#footer .container .nav-container .mobile-site-map .tab .tab-check:checked ~ .tab-label .icon-container [class^="icon-"],
                  #footer .container .nav-container .mobile-site-map .tab .tab-check:checked ~ .tab-label .icon-container [class*=" icon-"] {
                    opacity: 1;
                  }

#footer .container .nav-container .mobile-site-map .tab .tab-check:checked ~ .tab-content {
                max-height: 100vh;
              }

#footer .container .nav-container .mobile-site-map .tab .tab-content {
            max-height: 0px;
            overflow: hidden;
            background-color: rgba(var(--reserva-blanco-rgb), 0.04);
            transition: max-height 0.5s ease-out;
          }

#footer .container .nav-container .mobile-site-map .tab .tab-content .sub-menu-item {
              padding-top: 0.5rem;
              padding-bottom: 0.5rem;
              margin-left: 1rem;
              border-bottom: 1px solid rgba(var(--reserva-oscurisima-rgb), 0.08);
            }

#footer .container .nav-container .mobile-site-map .tab .tab-content .sub-menu-item a {
                --text-opacity: 1;
                color: #ffffff;
                color: rgba(255, 255, 255, var(--text-opacity));
              }

#footer .container .nav-container .mobile-site-map .tab .tab-content .sub-menu-item a [class^="icon-"],
                #footer .container .nav-container .mobile-site-map .tab .tab-content .sub-menu-item a [class*=" icon-"] {
                  margin-left: 0.75rem;
                }

#footer .container .nav-container .mobile-site-map .tab .tab-content .sub-menu-item:first-child {
                padding-top: 0.5rem;
              }

#footer .container .nav-container .mobile-site-map .tab .tab-content .sub-menu-item:last-child {
                padding-bottom: 0.5rem;
              }

#footer .container .second-row-container {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

@media (min-width: 1024px) {

#footer .container .second-row-container {
        grid-gap: 10rem;

        gap: 10rem
    }
      }

#footer .container .second-row-container .featured-links {
        grid-column: span 3 / span 3;
        margin-top: 1.5rem;
      }

@media (min-width: 1024px) {

#footer .container .second-row-container .featured-links {
          grid-column: span 2 / span 2
      }
        }

#footer .container .second-row-container .featured-links ul {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-content: center;
          grid-column-gap: 1rem;
          -webkit-column-gap: 1rem;
          column-gap: 1rem;
          grid-row-gap: 1rem;
          row-gap: 1rem;
        }

#footer .container .second-row-container .featured-links ul li {
            flex: 1 1 auto;
            padding: 0.75rem;
            background-color: rgba(255, 255, 255, 0.06);
          }

#footer .container .second-row-container .featured-links ul li a {
              display: flex;
              justify-content: space-between;
              --text-opacity: 1;
              color: #ffffff;
              color: rgba(255, 255, 255, var(--text-opacity));
            }

#footer .container .second-row-container .featured-links ul li a [class^="icon-"],
              #footer .container .second-row-container .featured-links ul li a [class*=" icon-"] {
                margin-right: 1rem;
                margin-top: 4px;
              }

#footer .container .second-row-container .featured-links ul li a [class^="icon-"]:before, #footer .container .second-row-container .featured-links ul li a [class*=" icon-"]:before {
                  --text-opacity: 1;
                  color: #ffffff;
                  color: rgba(255, 255, 255, var(--text-opacity));
                  font-size: 1rem;
                }

#footer .footer-band {
    margin-top: 3rem;
    background-color: var(--reserva-oscura);
  }

#footer .footer-band .container {
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: center;
      padding-top: 1rem;
      border-top: 1px solid rgba(var(--reserva-blanco-rgb), 0.4);
      grid-column-gap: 2rem;
      -webkit-column-gap: 2rem;
      column-gap: 2rem;
    }

@media (min-width: 768px) {

#footer .footer-band .container {
        flex-wrap: nowrap
    }
      }

#footer .footer-band .container .logo {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2rem;
        object-fit: cover;
        height: 64px;
        width: 233px;
      }

@media (min-width: 768px) {

#footer .footer-band .container .logo {
          margin-left: 0;

          margin-right: 0;

          margin: 24px 0
      }
        }

#footer .footer-band .container .brand-info {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-content: center;
        text-align: center;
        grid-column-gap: 1rem;
        -webkit-column-gap: 1rem;
        column-gap: 1rem;
        grid-row-gap: 1rem;
        row-gap: 1rem;
      }

@media (min-width: 768px) {

#footer .footer-band .container .brand-info {
          text-align: right
      }
        }

@media (min-width: 768px) {

#footer .footer-band .container .brand-info .links {
            text-align: right
        }
          }

#footer .footer-band .container .brand-info .links li {
            display: inline-block;
          }

@media (max-width: 639px) {
                #footer .footer-band .container .brand-info .links li:first-child:after {
                  content: "|";
                  color: white;
                }
                #footer .footer-band .container .brand-info .links li:first-child .link {
                  margin-right: 1rem;
                }

              #footer .footer-band .container .brand-info .links li:last-child {
                width: 100%;
              }
            }

#footer .footer-band .container .brand-info .links li:not(:first-child) .link {
                margin-left: 1rem;
              }

@media (min-width: 640px) {
                #footer .footer-band .container .brand-info .links li:not(:last-child):after {
                  content: "|";
                  color: white;
                }
                #footer .footer-band .container .brand-info .links li:not(:last-child) .link {
                  margin-right: 1rem;
                }
            }

#footer .footer-band .container .brand-info .links li .link {
              --text-opacity: 1;
              color: #ffffff;
              color: rgba(255, 255, 255, var(--text-opacity));
              font-size: 1rem;
            }

#footer .footer-band .container .brand-info p {
          --text-opacity: 1;
          color: #ffffff;
          color: rgba(255, 255, 255, var(--text-opacity));
          flex-basis: 100%;
        }

@media (min-width: 768px) {

#footer .footer-band .container .brand-info p {
            text-align: right
        }
          }
