.responsive-grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 2.5rem;
    gap: 2.5rem;
  }
    @media (min-width: 768px) {.responsive-grid {
      grid-template-columns: repeat(2, minmax(0, 1fr))
  }
    }
    @media (min-width: 1024px) {.responsive-grid {
      grid-template-columns: repeat(3, minmax(0, 1fr))
  }
    }
    @media (min-width: 1280px) {.responsive-grid {
      grid-template-columns: repeat(4, minmax(0, 1fr))
  }
    }

.primary-button,
.ms-rtestate-field .primary-button {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  --text-opacity: 1;
  color: #ffffff;
  color: rgba(255, 255, 255, var(--text-opacity));
  --bg-opacity: 1;
  background-color: #00AEf0;
  background-color: rgba(0, 174, 240, var(--bg-opacity));
  display: inline-block;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-family: var(--reserva-font);
  border-radius: 4px;
}

@media (min-width: 768px) {

.primary-button,
.ms-rtestate-field .primary-button {
    font-size: 1.25rem
}
  }

.primary-button-outline,
.ms-rtestate-field .primary-button-outline {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  --text-opacity: 1;
  color: #00AEf0;
  color: rgba(0, 174, 240, var(--text-opacity));
  display: inline-block;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-weight: 600;
  border: 2px solid var(--reserva-clara);
  border-radius: 4px;
}

@media (min-width: 768px) {

.primary-button-outline,
.ms-rtestate-field .primary-button-outline {
    font-size: 1.25rem
}
  }

.plus-minus {
  position: relative;
  width: 24px;
  height: 24px;
}

.plus-minus:before,
  .plus-minus:after {
    content: "";
    position: absolute;
    background-color: var(--reserva-clara);
    transition: -webkit-transform 0.25s ease-out;
    transition: transform 0.25s ease-out;
    transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
  }

/* Vertical line */

.plus-minus:before {
    top: 0;
    left: 50%;
    width: 4px;
    height: 100%;
    margin-left: -2px;
  }

/* horizontal line */

.plus-minus:after {
    top: 50%;
    left: 0;
    width: 100%;
    height: 4px;
    margin-top: -2px;
  }

.plus-minus.minus:before {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
    }

.plus-minus.minus:after {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
    }

.white-box {
  padding: 4rem;
  background-color: var(--reserva-blanco);
  border-radius: 4px;
  box-shadow: 2px 2px 16px #1a43591a;
}

ul.bullet-list li {
    padding: 1rem;
    font-size: 1rem;
    color: var(--reserva-oscura);
    background-color: rgba(var(--reserva-oscura-rgb), 0.06);
    border-radius: 4px;
    -webkit-column-break-inside: avoid;
            break-inside: avoid;
  }

ul.bullet-list li:not(:last-of-type) {
      margin-bottom: 1rem;
    }

ul.bullet-list li:before {
      margin-right: 1rem;
      color: var(--reserva-clara);
      content: "\2022";
      font-size: 1.5rem;
    }

@media (min-width: 768px) {

ul.bullet-list li {
      font-size: 1.5rem
  }
    }

ul.number-list li {
    position: relative;
    padding: 1rem;
    padding-left: 3rem;
    font-size: 1rem;
    color: var(--reserva-oscura);
    background-color: rgba(var(--reserva-oscura-rgb), 0.06);
    border-radius: 4px;
    counter-increment: list;
    -webkit-column-break-inside: avoid;
            break-inside: avoid;
  }

ul.number-list li:not(:last-of-type) {
      margin-bottom: 1rem;
    }

ul.number-list li:before {
      position: absolute;
      --text-opacity: 1;
      color: #00AEf0;
      color: rgba(0, 174, 240, var(--text-opacity));
      font-weight: 700;
      left: 1rem;
      top: 1rem;
      content: counter(list) ".";
      font-size: 1.5rem;
    }

@media (min-width: 768px) {

ul.number-list li {
      font-size: 1.5rem
  }
    }

.card {
  background-color: var(--reserva-blanco);
  box-shadow: 2px 2px 16px #1a43591a;
}

.card a {
    display: block;
    padding: 1.5rem;
  }

.card a .card-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1.5rem;
    }

.card a .card-info .text {
        font-weight: 600;
      }

.card a .card-info i:before, .card a .card-info [class^="icon-"]:before, .card a .card-info [class*=" icon-"]:before {
          color: var(--reserva-plata);
        }

.filtro-documentos {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  color: var(--reserva-oscura);
}

@media (min-width: 640px) {

.filtro-documentos {
    flex-wrap: nowrap;

    white-space: nowrap
}
  }

@media (max-width: 1279px) {

.filtro-documentos {
    overflow-x: scroll
}
  }

.filtro-documentos .datepicker-container {
    display: inline-block;
    margin-top: 0;
    flex: 1 1 100%;
  }

@media (min-width: 640px) {

.filtro-documentos .datepicker-container {
      margin-top: 1.5rem;

      flex: 0 1 auto
  }
    }

@media (min-width: 1280px) {

.filtro-documentos .datepicker-container {
      margin-top: 0
  }
    }

@media (min-width: 640px) {
          .filtro-documentos .datepicker-container:not(:last-child) .datepicker:after {
            margin-left: 1rem;
            content: "|";
            font-size: 1.5rem;
            color: rgba(var(--reserva-oscura-rgb), 0.24);
          }
        }

.filtro-documentos .datepicker-container .datepicker {
      display: flex;
      align-items: center;
    }

.filtro-documentos .datepicker-container .datepicker input {
        width: 0;
      }

.filtro-documentos .datepicker-container .datepicker input:focus {
          outline: none;
        }

.filtro-documentos .datepicker-container .datepicker label {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        flex: 1 1 100%;
        font-size: 1.25rem;
        border: 2px solid rgba(var(--reserva-oscura-rgb), 0.24);
        border-radius: 4px;
      }

@media (max-width: 640px) {

.filtro-documentos .datepicker-container .datepicker label {
          display: flex;

          justify-content: space-between
      }
        }

.filtro-documentos .datepicker-container .datepicker label i,
        .filtro-documentos .datepicker-container .datepicker label [class^="icon-"],
        .filtro-documentos .datepicker-container .datepicker label [class*=" icon-"] {
          margin-right: 0.75rem;
        }

@media (max-width: 640px) {

.filtro-documentos .datepicker-container .datepicker label .title {
            display: flex;

            align-items: center
        }
          }

.filtro-documentos .datepicker-container .datepicker label .selected-year {
          font-weight: 700;
        }

.filtro-documentos .type-picker {
    display: inline-block;
    flex: 1 1 100%;
  }

@media (min-width: 640px) {

.filtro-documentos .type-picker {
      flex: 0 1 auto
  }
    }

.filtro-documentos .type-picker ul.document-types {
      display: none;
    }

@media (min-width: 1280px) {

.filtro-documentos .type-picker ul.document-types {
        display: flex;

        flex-wrap: nowrap
    }
      }

.filtro-documentos .type-picker ul.document-types .document-type {
        display: inline-block;
      }

.filtro-documentos .type-picker ul.document-types .document-type:first-child {
          margin-left: 1rem;
        }

.filtro-documentos .type-picker ul.document-types .document-type:not(:last-child) {
          margin-right: 1rem;
        }

.filtro-documentos .type-picker ul.document-types .document-type.active button {
            color: var(--reserva-blanco);
            background-color: var(--reserva-oscura);
          }

.filtro-documentos .type-picker ul.document-types .document-type button {
          padding-left: 1rem;
          padding-right: 1rem;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          font-size: 1.25rem;
          font-weight: 600;
          border-radius: 4px;
          background-color: rgba(var(--reserva-oscura-rgb), 0.08);
        }

.filtro-documentos .type-picker .form-container {
      display: flex;
      flex-wrap: wrap;
      margin-top: 1rem;
    }

@media (min-width: 640px) {

.filtro-documentos .type-picker .form-container {
        margin-left: 1rem;

        margin-top: 0
    }
      }

@media (min-width: 1280px) {

.filtro-documentos .type-picker .form-container {
        display: none
    }
      }

.filtro-documentos .type-picker .form-container label {
        margin-bottom: 0.5rem;
        flex: 1 1 100%;
      }

@media (min-width: 640px) {

.filtro-documentos .type-picker .form-container label {
          margin-bottom: 0
      }
        }

.filtro-documentos .type-picker .form-container select {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        padding-left: 1rem;
        padding-right: 1rem;
        font-weight: 600;
        color: var(--reserva-oscura);
        background-color: var(--reserva-blanco);
        flex: 1 1 100%;
        border: 2px solid rgba(var(--reserva-oscura-rgb), 0.24);
        border-radius: 4px;
      }

@media (min-width: 640px) {

.filtro-documentos .type-picker .form-container select {
          flex: 0 1 auto
      }
        }

.filtro-documentos .form-container {
    display: inline-block;
  }

@media (min-width: 1280px) {

.filtro-documentos .form-container {
      display: none
  }
    }

.documentos {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-gap: 1rem;
  gap: 1rem;
}

.documentos .documento {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    grid-gap: 2rem;
    gap: 2rem;
    box-shadow: 2px 2px 16px #1a43591a;
    background-color: var(--reserva-blanco);
  }

@media (max-width: 639px) {

.documentos .documento {
      grid-gap: 1rem;

      gap: 1rem
  }
    }

@media (min-width: 768px) {

.documentos .documento {
      padding: 1.5rem
  }
    }

@media (min-width: 1024px) {

.documentos .documento.mobile {
        display: none
    }
      }

@media (max-width: 1023px) {

.documentos .documento.desktop {
        display: none
    }
      }

.documentos .documento .type {
      flex: 1 0 50px;
    }

.documentos .documento .info {
      flex: 1 1 100%;
    }

@media (max-width: 639px) {

.documentos .documento .info .title {
          font-size: 1rem
      }
        }

.documentos .documento .info .details {
        display: flex;
        grid-gap: 1rem;
        gap: 1rem;
        font-size: 1rem;
        color: var(--reserva-oscura);
      }

@media (max-width: 639px) {

.documentos .documento .info .details {
          font-size: 0.75rem
      }
        }

.documentos .documento .info .details .data {
          font-weight: 600;
        }

.documentos .documento .link-container a {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        color: var(--reserva-blanco);
      }

@media (max-width: 1023px) {

.documentos .documento .link-container a {
          display: none
      }
        }

.documentos .documento .link-container a:hover [class^="icon-"]:before, .documentos .documento .link-container a:hover [class*=" icon-"]:before {
              opacity: 1;
            }

.documentos .documento .link-container a [class^="icon-"],
        .documentos .documento .link-container a [class*=" icon-"] {
          margin-left: 0.75rem;
        }

.documentos .documento .link-container a [class^="icon-"]:before, .documentos .documento .link-container a [class*=" icon-"]:before {
            color: var(--reserva-blanco);
            opacity: 0.5;
            transition: opacity 250ms ease-in-out;
          }

.custom-select-container {
  display: flex;
  position: relative;
  flex: 1 1 100%;
}

@media (min-width: 640px) {

.custom-select-container {
    flex: 0 1 auto
}
  }

.custom-select-container select {
    -webkit-appearance: none;
            appearance: none;
  }

.custom-select-container::after {
    position: absolute;
    opacity: 0.5;
    font-family: "icomoon";
    content: "\e928";
    top: 50%;
    right: 1rem;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }

.form .field {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

.form .field label {
      --text-opacity: 1;
      color: #264E72;
      color: rgba(38, 78, 114, var(--text-opacity));
      font-weight: 600;
      margin-bottom: 0.5rem;
      font-size: 1rem;
    }

.form .field input {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      padding-left: 1rem;
      padding-right: 1rem;
      --bg-opacity: 1;
      background-color: #ffffff;
      background-color: rgba(255, 255, 255, var(--bg-opacity));
      border: 2px solid #264e723d;
      border-radius: 4px;
    }

.form .field select {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      padding-left: 1rem;
      padding-right: 1rem;
      --bg-opacity: 1;
      background-color: #ffffff;
      background-color: rgba(255, 255, 255, var(--bg-opacity));
      border: 2px solid #264e723d;
      border-radius: 4px;
    }

.form .field textarea {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      padding-left: 1rem;
      padding-right: 1rem;
      --bg-opacity: 1;
      background-color: #ffffff;
      background-color: rgba(255, 255, 255, var(--bg-opacity));
      border: 2px solid #264e723d;
      border-radius: 4px;
    }

.form .submit-container {
    margin-top: 1.5rem;
  }

.pagination-container {
  display: flex;
  justify-content: center;
}

.pagination-container .pagination {
    display: flex;
    align-items: center;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    --bg-opacity: 1;
    background-color: #ffffff;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
    padding: 1.25rem;
    border-radius: 4px;
    box-shadow: 2px 2px 16px #1a43591a;
  }

.pagination-container .pagination li {
      --text-opacity: 1;
      color: #1B3A57;
      color: rgba(27, 58, 87, var(--text-opacity));
    }

.pagination-container .pagination li.active button {
          --text-opacity: 1;
          color: #00AEf0;
          color: rgba(0, 174, 240, var(--text-opacity));
          font-weight: 700;
        }

.pagination-container .pagination li.prev button i:before, .pagination-container .pagination li.prev button [class^="icon-"]:before, .pagination-container .pagination li.prev button [class*=" icon-"]:before {
              -webkit-transform: rotate(180deg);
                      transform: rotate(180deg);
            }

.pagination-container .pagination li button {
        border: none;
        font-size: 1.25rem;
      }

.pagination-container .pagination li button:disabled {
          opacity: 0.6;
        }

.pagination-container .pagination li i:before, .pagination-container .pagination li [class^="icon-"]:before, .pagination-container .pagination li [class*=" icon-"]:before {
          display: block;
          --text-opacity: 1;
          color: #1B3A57;
          color: rgba(27, 58, 87, var(--text-opacity));
          margin-top: 4px;
          font-size: 1.25rem;
        }

:root {
  --reserva-oscurisima: #1b3a57;
  --reserva-oscurisima-rgb: 27, 58, 87;
  --reserva-oscura: #264e72;
  --reserva-oscura-rgb: 38, 78, 114;
  --reserva-clara: #00aef0;
  --reserva-clara-rgb: 0, 174, 240;
  --reserva-naranja: #f89420;
  --reserva-naranja-rgb: 248, 148, 32;
  --reserva-verde: #a9b737;
  --reserva-grafito: #626266;
  --reserva-grafito-rgb: 98, 98, 102;
  --reserva-plata: #b1b3b5;
  --reserva-plata-rgb: 177, 179, 181;
  --reserva-blanco: #ffffff;
  --reserva-blanco-rgb: 255, 255, 255;
  --reserva-gris: #f1f3f6;
  --reserva-gris-rgb: 241, 243, 246;
  --reserva-negro: #000000;
  --reserva-negro-rgb: 0, 0, 0;
}

:root {
  --sm-breakpoint: 640px;
  --md-breakpoint: 768px;
  --lg-breakpoint: 1024px;
  --xl-breakpoint: 1280px;
  --xxl-breakpoint: 1600px;
}

.container {
  margin-left: auto;
  margin-right: auto;
  transition: padding 0.5s ease-in-out;
}

@media (max-width: 639px) {

.container {
    padding-left: 1rem;

    padding-right: 1rem
}
  }

@media (min-width: 640px) and (max-width: calc(640px + 48px)) {

.container {
    padding-left: 3rem;

    padding-right: 3rem
}
  }

@media (min-width: 768px) and (max-width: calc(768px + 48px)) {

.container {
    padding-left: 3rem;

    padding-right: 3rem
}
  }

@media (min-width: 1024px) and (max-width: calc(1024px + 48px)) {

.container {
    padding-left: 3rem;

    padding-right: 3rem
}
  }

@media (min-width: 1280px) and (max-width: calc(1280px + 48px)) {

.container {
    padding-left: 3rem;

    padding-right: 3rem
}
  }

@media (min-width: 1600px) {
  /* Agregando soporte para resoluciones 1080 */

  body .container {
    max-width: 1472px;
  }
}

/* Clases globales para agregar funcionalidades básicas responsive */

@media (max-width: 1023px) {

.desktop-item {
    display: none !important
}
  }

@media (min-width: 1024px) {

.mobile-item {
    display: none !important
}
  }

html {
  font-size: 100%;
}

html * {
    font-family: var(--reserva-font);
  }

/* TEXT */

html p,
  html .ms-rtestate-field p {
    --text-opacity: 1;
    color: #264E72;
    color: rgba(38, 78, 114, var(--text-opacity));
    font-size: 1rem;
  }

html p.big-text, html .ms-rtestate-field p.big-text {
      font-size: 1rem;
    }

@media (min-width: 768px) {

html p.big-text, html .ms-rtestate-field p.big-text {
        font-size: 1.25rem
    }
      }

html a,
  html .ms-rtestate-field a {
    --text-opacity: 1;
    color: #264E72;
    color: rgba(38, 78, 114, var(--text-opacity));
    font-size: 1rem;
  }

html a.big-text, html .ms-rtestate-field a.big-text {
      font-size: 1rem;
    }

@media (min-width: 768px) {

html a.big-text, html .ms-rtestate-field a.big-text {
        font-size: 1.25rem
    }
      }

/* TITLES */

html h1,
  html .ms-rtestate-field h1 {
    font-weight: 700;
    --text-opacity: 1;
    color: #00AEf0;
    color: rgba(0, 174, 240, var(--text-opacity));
    font-size: 2rem;
  }

@media (min-width: 768px) {

html h1,
  html .ms-rtestate-field h1 {
      font-size: 3rem
  }
    }

@media (min-width: 1024px) {

html h1,
  html .ms-rtestate-field h1 {
      font-size: 4rem
  }
    }

html h2,
  html .ms-rtestate-field h2 {
    font-weight: 700;
    --text-opacity: 1;
    color: #00AEf0;
    color: rgba(0, 174, 240, var(--text-opacity));
    font-size: 1.5rem;
  }

@media (min-width: 768px) {

html h2,
  html .ms-rtestate-field h2 {
      font-size: 2rem
  }
    }

@media (min-width: 1024px) {

html h2,
  html .ms-rtestate-field h2 {
      font-size: 2.5rem
  }
    }

html h3,
  html .ms-rtestate-field h3 {
    font-weight: 700;
    --text-opacity: 1;
    color: #00AEf0;
    color: rgba(0, 174, 240, var(--text-opacity));
    font-size: 1.5rem;
  }

@media (min-width: 768px) {

html h3,
  html .ms-rtestate-field h3 {
      font-size: 1.75rem
  }
    }

@media (min-width: 1024px) {

html h3,
  html .ms-rtestate-field h3 {
      font-size: 2rem
  }
    }

html h4,
  html .ms-rtestate-field h4 {
    font-weight: 700;
    --text-opacity: 1;
    color: #264E72;
    color: rgba(38, 78, 114, var(--text-opacity));
    font-size: 1rem;
  }

@media (min-width: 768px) {

html h4,
  html .ms-rtestate-field h4 {
      font-size: 1.25rem
  }
    }

@media (min-width: 1024px) {

html h4,
  html .ms-rtestate-field h4 {
      font-size: 1.5rem
  }
    }

/* FORM ITEMS */

html select .ms-rtestate-field select {
    --text-opacity: 1;
    color: #264E72;
    color: rgba(38, 78, 114, var(--text-opacity));
    font-size: 1rem;
  }

@-webkit-keyframes SlideInFromLeft {
  0% {
    max-width: 0px;
  }

  100% {
    max-width: 100vw;
  }
}

@keyframes SlideInFromLeft {
  0% {
    max-width: 0px;
  }

  100% {
    max-width: 100vw;
  }
}

@-webkit-keyframes SlideInFromBottom {
  0% {
    max-height: 0px;
  }

  100% {
    max-height: 100vh;
  }
}

@keyframes SlideInFromBottom {
  0% {
    max-height: 0px;
  }

  100% {
    max-height: 100vh;
  }
}

.news-card {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-gap: 1.5rem;
  gap: 1.5rem;
  padding: 1.5rem;
  background-color: var(--reserva-blanco);
  box-shadow: 2px 2px 16px #1a43591a;
  border-radius: 4px;
}

@media (min-width: 768px) {

.news-card {
    grid-template-columns: repeat(2, minmax(0, 1fr))
}
  }

.news-card.highlight {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-row: span 2 / span 2;
  }

@media (min-width: 640px) {

.news-card.highlight .img-container {
        height: 410px
    }
      }

.news-card.highlight .news-content .news-summary {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }

.news-card .img-container {
    height: 190px;
  }

@media (min-width: 768px) {

.news-card .img-container {
      height: 250px
  }
    }

.news-card .img-container img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      border-radius: 4px;
    }

.news-card .news-content {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    grid-gap: 1rem;
    gap: 1rem;
  }

.news-card .news-content .news-title {
      font-size: 1rem;
      color: var(--reserva-oscura);
    }

@media (min-width: 768px) {

.news-card .news-content .news-title {
        font-size: 1.25rem
    }
      }

@media (min-width: 1024px) {

.news-card .news-content .news-title {
        font-size: 1.5rem
    }
      }

.news-card .news-content .news-summary {
      --text-opacity: 1;
      color: #264E72;
      color: rgba(38, 78, 114, var(--text-opacity));
      font-size: 14px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
    }

@media (min-width: 768px) {

.news-card .news-content .news-summary {
        font-size: 1rem;

        overflow: hidden;

        display: -webkit-box;

        -webkit-box-orient: vertical;

        -webkit-line-clamp: 3
    }
      }

@media (min-width: 1280px) {

.news-card .news-content .news-summary {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5
    }
      }

.news-card .news-content .news-info {
      flex: 1 1 0%;
      display: flex;
      justify-content: space-between;
    }

.news-card .news-content .news-info .date {
        font-weight: 700;
        color: var(--reserva-grafito);
      }

.news-card .news-content .news-info .link-container a {
          font-weight: 700;
          color: var(--reserva-clara);
        }

#quienes-somos .intro {
    padding-top: 4rem;
    padding-bottom: 4rem;
    --bg-opacity: 1;
    background-color: #ffffff;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
  }

#quienes-somos .intro .container {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      grid-gap: 2.5rem;
      gap: 2.5rem;
    }

@media (min-width: 1024px) {

#quienes-somos .intro .container {
        grid-template-columns: repeat(2, minmax(0, 1fr))
    }
      }

#quienes-somos .intro .container .img-container img {
          border-radius: 4px;
        }

#quienes-somos .intro .container p {
        --text-opacity: 1;
        color: #264E72;
        color: rgba(38, 78, 114, var(--text-opacity));
        text-align: justify;
      }

@media (min-width: 768px) {

#quienes-somos .intro .container p {
          font-size: 1.5rem
      }
        }

#quienes-somos .intro .container h4 {
        margin-bottom: 1rem;
        margin-top: 2rem;
        --text-opacity: 1;
        color: #264E72;
        color: rgba(38, 78, 114, var(--text-opacity));
        font-size: 1.25rem;
      }

@media (min-width: 768px) {

#quienes-somos .intro .container h4 {
          font-size: 2rem
      }
        }

#quienes-somos .values .values-list {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      grid-gap: 2.5rem;
      gap: 2.5rem;
      padding-top: 4rem;
      padding-bottom: 2rem;
    }

@media (min-width: 768px) {

#quienes-somos .values .values-list {
        grid-template-columns: repeat(2, minmax(0, 1fr))
    }
      }

@media (min-width: 1280px) {

#quienes-somos .values .values-list {
        grid-template-columns: repeat(3, minmax(0, 1fr))
    }
      }

#quienes-somos .values .values-list > li {
        padding: 2rem;
        --bg-opacity: 1;
        background-color: #ffffff;
        background-color: rgba(255, 255, 255, var(--bg-opacity));
        border-top: 8px solid var(--reserva-oscura);
        border-radius: 4px;
        box-shadow: 2px 2px 16px #1a43591a;
        transition: -webkit-transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
      }

#quienes-somos .values .values-list > li:hover {
          -webkit-transform: scale(1.05);
          transform: scale(1.05);
        }

@media (min-width: 1024px) {
          #quienes-somos .values .values-list > li:hover {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
          }
        }

#quienes-somos .values .values-list > li .icon-container {
          width: 100%;
          text-align: center;
          margin-bottom: 2rem;
        }

#quienes-somos .values .values-list > li .icon-container i:before {
              font-size: 3rem;
            }

#quienes-somos .values .values-list > li .icon-container img {
            margin-left: auto;
            margin-right: auto;
          }

#quienes-somos .values .values-list > li h3 {
          margin-bottom: 1.25rem;
          text-align: center;
          font-size: 1.5rem;
        }

@media (min-width: 768px) {

#quienes-somos .values .values-list > li h3 {
            font-size: 2rem
        }
          }

#quienes-somos .values .values-list > li p {
          --text-opacity: 1;
          color: #264E72;
          color: rgba(38, 78, 114, var(--text-opacity));
          text-align: justify;
          font-weight: 600;
        }

@media (min-width: 768px) {

#quienes-somos .values .values-list > li p {
            font-size: 1.25rem;

            line-height: 27px
        }
          }

#quienes-somos .values .values-list > li ul {
          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }

#quienes-somos .values .values-list > li ul li {
            font-weight: 600;
          }

@media (min-width: 768px) {

#quienes-somos .values .values-list > li ul li {
              font-size: 1.25rem
          }
            }

#quienes-somos .values .values-list > li ul li:before {
              margin-right: 1rem;
              color: var(--reserva-clara);
              content: "\2022";
              font-size: 1.5rem;
            }

#quienes-somos #manager-words {
    padding-top: 1rem;
    padding-bottom: 2rem;
    background-color: var(--reserva-gris);
  }

@media (min-width: 1024px) {

#quienes-somos #manager-words {
      padding-top: 2rem;

      padding-bottom: 4rem
  }
    }

#quienes-somos #manager-words .container {
      margin-left: auto;
      margin-right: auto;
    }

#quienes-somos #manager-words .container h2 {
        margin-bottom: 4rem;
        color: var(--reserva-clara);
      }

#quienes-somos #manager-words .container .quote-container {
        position: relative;
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }

@media (min-width: 1280px) {

#quienes-somos #manager-words .container .quote-container {
          grid-template-columns: repeat(2, minmax(0, 1fr))
      }
        }

#quienes-somos #manager-words .container .quote-container .image-container {
          z-index: 2;
        }

@media (min-width: 1280px) {

#quienes-somos #manager-words .container .quote-container .image-container {
            margin-left: 4rem
        }
          }

#quienes-somos #manager-words .container .quote-container .image-container img {
            margin-left: auto;
            margin-right: auto;
            height: 100%;
            aspect-ratio: 1/1;
            object-fit: cover;
            object-position: top;
            border-radius: 4px;
            max-width: 80%;
          }

@media (min-width: 1280px) {

#quienes-somos #manager-words .container .quote-container .image-container img {
              margin-left: 0;

              margin-right: 0;

              max-width: 100%
          }
            }

#quienes-somos #manager-words .container .quote-container .background {
          position: absolute;
          width: 100%;
          background-color: var(--reserva-clara);
          border-radius: 4px;
          height: 90%;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
        }

#quienes-somos #manager-words .container .quote-container .quote {
          display: flex;
          flex-wrap: wrap;
          align-content: space-evenly;
          padding-top: 4rem;
          padding-bottom: 4rem;
          padding-left: 2rem;
          padding-right: 2rem;
          z-index: 2;
        }

@media (min-width: 1024px) {

#quienes-somos #manager-words .container .quote-container .quote {
            margin-top: auto;

            margin-bottom: auto
        }
          }

#quienes-somos #manager-words .container .quote-container .quote p,
          #quienes-somos #manager-words .container .quote-container .quote .text {
            max-width: 100%;
            max-height: 100%;
            overflow: hidden;
            text-align: justify;
            color: var(--reserva-blanco);
            font-size: 1rem;
          }

@media (min-width: 768px) {

#quienes-somos #manager-words .container .quote-container .quote p,
          #quienes-somos #manager-words .container .quote-container .quote .text {
              font-size: 1.5rem
          }
            }

#quienes-somos #manager-words .container .quote-container .quote .signature {
            margin-top: 1.5rem;
            padding-bottom: 2rem;
            color: var(--reserva-blanco);
          }

#quienes-somos #manager-words .container .quote-container .quote .signature .name {
              display: block;
              font-weight: 700;
              font-size: 1.25rem;
            }

@media (min-width: 1024px) {

#quienes-somos #manager-words .container .quote-container .quote .signature .name {
                font-size: 1.5rem
            }
              }

#quienes-somos #manager-words .container .quote-container .quote .signature .position {
              display: block;
              font-size: 1.5rem;
            }

@media (min-width: 1024px) {

#quienes-somos #manager-words .container .quote-container .quote .signature .position {
                font-size: 1.125rem
            }
              }

#quienes-somos .committee-members {
    --bg-opacity: 1;
    background-color: #ffffff;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
    padding-top: 4rem;
    padding-bottom: 2rem;
  }

#quienes-somos .committee-members .main-title {
      --text-opacity: 1;
      color: #00AEf0;
      color: rgba(0, 174, 240, var(--text-opacity));
    }

#quienes-somos .committee-members .members-container {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      grid-gap: 2.5rem;
      gap: 2.5rem;
      margin-top: 2rem;
    }

@media (min-width: 768px) {

#quienes-somos .committee-members .members-container {
        grid-template-columns: repeat(2, minmax(0, 1fr))
    }
      }

@media (min-width: 1024px) {

#quienes-somos .committee-members .members-container {
        grid-template-columns: repeat(3, minmax(0, 1fr))
    }
      }

@media (min-width: 1600px) {

#quienes-somos .committee-members .members-container {
        grid-template-columns: repeat(4, minmax(0, 1fr))
    }
      }

#quienes-somos .committee-members .members-container .member {
        padding: 1.5rem;
        padding-bottom: 2rem;
        border-radius: 0.25rem;
        background-color: rgba(var(--reserva-oscura-rgb), 0.06);
      }

#quienes-somos .committee-members .members-container .member .img-container {
          margin-bottom: 1.5rem;
        }

#quienes-somos .committee-members .members-container .member .img-container img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

#quienes-somos .committee-members .members-container .member .member-info {
          display: grid;
          grid-template-columns: repeat(1, minmax(0, 1fr));
          align-items: center;
          text-align: center;
        }

#quienes-somos .committee-members .members-container .member .member-info .name {
            margin-bottom: 0.5rem;
            --text-opacity: 1;
            color: #264E72;
            color: rgba(38, 78, 114, var(--text-opacity));
            font-weight: 700;
            font-size: 1.5rem;
          }

#quienes-somos .committee-members .members-container .member .member-info .position {
            --text-opacity: 1;
            color: #264E72;
            color: rgba(38, 78, 114, var(--text-opacity));
            font-size: 1rem;
          }

#quienes-somos .board-directors {
    --bg-opacity: 1;
    background-color: #ffffff;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
    padding-top: 2rem;
    padding-bottom: 4rem;
  }

#quienes-somos .board-directors p,
    #quienes-somos .board-directors .intro-text {
      margin-top: 2rem;
      --text-opacity: 1;
      color: #264E72;
      color: rgba(38, 78, 114, var(--text-opacity));
    }

@media (min-width: 768px) {

#quienes-somos .board-directors p,
    #quienes-somos .board-directors .intro-text {
        font-size: 1.5rem
    }
      }

#quienes-somos .board-directors .main-title {
      --text-opacity: 1;
      color: #00AEf0;
      color: rgba(0, 174, 240, var(--text-opacity));
    }

#quienes-somos .board-directors .second-title {
      --text-opacity: 1;
      color: #264E72;
      color: rgba(38, 78, 114, var(--text-opacity));
    }

#quienes-somos .board-directors h3 {
      margin-bottom: 2rem;
      margin-top: 4rem;
    }

#quienes-somos .board-directors .bullet-list {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      grid-gap: 2.5rem;
      gap: 2.5rem;
    }

@media (min-width: 1024px) {

#quienes-somos .board-directors .bullet-list {
        grid-template-columns: repeat(2, minmax(0, 1fr))
    }
      }

@media (min-width: 1600px) {

#quienes-somos .board-directors .bullet-list {
        grid-template-columns: repeat(3, minmax(0, 1fr))
    }
      }

#quienes-somos .board-directors .bullet-list li {
        position: relative;
        padding-top: 2rem;
        padding-bottom: 2rem;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        margin-bottom: 0;
      }

#quienes-somos .board-directors .bullet-list li:before {
          position: absolute;
          left: 1.5rem;
        }

@media (max-width: 767px) {

#quienes-somos .board-directors .bullet-list li:before {
            line-height: 1.5rem
        }
          }

#quienes-somos .board-directors .bullet-list li .name {
          font-weight: 700;
        }

@media (min-width: 768px) {

#quienes-somos .board-directors .bullet-list li .name {
            font-size: 1.5rem
        }
          }

#quienes-somos .board-directors .bullet-list li .position {
          display: block;
          font-size: 0.75rem;
        }

@media (min-width: 768px) {

#quienes-somos .board-directors .bullet-list li .position {
            font-size: 1.25rem
        }
          }

#quienes-somos .board-directors .stakeholders {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      grid-gap: 2.5rem;
      gap: 2.5rem;
    }

@media (min-width: 1024px) {

#quienes-somos .board-directors .stakeholders {
        grid-template-columns: repeat(2, minmax(0, 1fr))
    }
      }

#quienes-somos .board-directors .stakeholders li {
        padding-top: 2rem;
        padding-bottom: 2rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        font-size: 1rem;
        color: var(--reserva-oscura);
        background-color: rgba(var(--reserva-oscura-rgb), 0.06);
        border-radius: 4px;
      }

@media (min-width: 768px) {

#quienes-somos .board-directors .stakeholders li {
          font-size: 1.5rem
      }
        }

#quienes-somos .awards {
    padding-top: 4rem;
  }

#quienes-somos .awards .years-selector {
      position: relative;
      margin-top: 4rem;
      margin-bottom: 4rem;
    }

#quienes-somos .awards .years-selector .line {
        position: absolute;
        width: 100%;
        font-size: 1.5rem;
        bottom: 24px;
        border: 4px solid var(--reserva-oscura);
        opacity: 0.12;
      }

#quienes-somos .awards .years-selector .years {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding-left: 4rem;
        padding-right: 4rem;
        padding-bottom: 1rem;
        overflow-x: auto;
        grid-gap: 7rem;
        gap: 7rem;
      }

#quienes-somos .awards .years-selector .years .year {
          display: inline-block;
        }

#quienes-somos .awards .years-selector .years .year button {
            border: none;
          }

#quienes-somos .awards .years-selector .years .year button:focus,
            #quienes-somos .awards .years-selector .years .year button:active {
              border: none;
              outline: none;
              background: transparent;
            }

#quienes-somos .awards .years-selector .years .year .point {
            display: block;
            margin-left: auto;
            margin-right: auto;
            --bg-opacity: 1;
            background-color: #264E72;
            background-color: rgba(38, 78, 114, var(--bg-opacity));
            width: 24px;
            height: 24px;
            border-radius: 100%;
          }

#quienes-somos .awards .years-list {
      display: flex;
      position: relative;
      padding-bottom: 1rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      padding-top: 0.5rem;
      overflow-x: auto;
      white-space: nowrap;
    }

#quienes-somos .awards .years-list .year-item {
        display: inline-block;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        --bg-opacity: 1;
        background-color: #ffffff;
        background-color: rgba(255, 255, 255, var(--bg-opacity));
        border-radius: 4px;
        box-shadow: 2px 2px 16px #1a43591a;
      }

#quienes-somos .awards .years-list .year-item:not(:last-child) {
          margin-right: 2.5rem;
        }

#quienes-somos .awards .years-list .year-item .year {
          display: flex;
          grid-gap: 2.5rem;
          gap: 2.5rem;
          width: -webkit-max-content;
          width: max-content;
        }

@media (min-width: 768px) {

#quienes-somos .awards .years-list .year-item .year {
            grid-gap: 4rem;

            gap: 4rem
        }
          }

#quienes-somos .awards .years-list .year-item .year .date {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2.5rem;
          }

@media (min-width: 768px) {

#quienes-somos .awards .years-list .year-item .year .date {
              font-size: 4rem
          }
            }

#quienes-somos .awards .years-list .year-item .year .awards {
            display: flex;
          }

#quienes-somos .awards .years-list .year-item .year .awards .award {
              display: grid;
              grid-template-columns: repeat(1, minmax(0, 1fr));
            }

#quienes-somos .awards .years-list .year-item .year .awards .award:not(:first-child) {
                padding-left: 2.5rem;
              }

#quienes-somos .awards .years-list .year-item .year .awards .award:not(:last-child) {
                padding-right: 2.5rem;
                border-right: 1px solid #1a43591a;
              }

#quienes-somos .awards .years-list .year-item .year .awards .award .info {
                white-space: normal;
                width: 300px;
              }

#quienes-somos .awards .years-list .year-item .year .awards .award .info .logo {
                  margin-bottom: 1.25rem;
                  width: 300px;
                  height: 240px;
                }

#quienes-somos .awards .years-list .year-item .year .awards .award .info .logo img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    border-radius: 2px;
                  }

#quienes-somos .awards .years-list .year-item .year .awards .award .info .title {
                  margin-bottom: 0.5rem;
                  font-weight: 700;
                  --text-opacity: 1;
                  color: #264E72;
                  color: rgba(38, 78, 114, var(--text-opacity));
                  font-size: 1rem;
                }

@media (min-width: 768px) {

#quienes-somos .awards .years-list .year-item .year .awards .award .info .title {
                    font-size: 1.5rem
                }
                  }

#quienes-somos .awards .years-list .year-item .year .awards .award .info .sub-title {
                  margin-bottom: 0.75rem;
                  font-weight: 700;
                  --text-opacity: 1;
                  color: #626266;
                  color: rgba(98, 98, 102, var(--text-opacity));
                  font-size: 0.75rem;
                  line-height: 0.75rem;
                }

@media (min-width: 768px) {

#quienes-somos .awards .years-list .year-item .year .awards .award .info .sub-title {
                    font-size: 1rem;

                    line-height: 1rem
                }
                  }

#quienes-somos .awards .years-list .year-item .year .awards .award .info .desc {
                  --text-opacity: 1;
                  color: #264E72;
                  color: rgba(38, 78, 114, var(--text-opacity));
                  font-size: 0.75rem;
                  line-height: 0.75rem;
                }

@media (min-width: 768px) {

#quienes-somos .awards .years-list .year-item .year .awards .award .info .desc {
                    font-size: 1rem;

                    line-height: 1rem
                }
                  }
