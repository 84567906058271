.responsive-grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 2.5rem;
    gap: 2.5rem;
  }
    @media (min-width: 768px) {.responsive-grid {
      grid-template-columns: repeat(2, minmax(0, 1fr))
  }
    }
    @media (min-width: 1024px) {.responsive-grid {
      grid-template-columns: repeat(3, minmax(0, 1fr))
  }
    }
    @media (min-width: 1280px) {.responsive-grid {
      grid-template-columns: repeat(4, minmax(0, 1fr))
  }
    }

.brief-card {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  padding: 1.5rem;
  --bg-opacity: 1;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  height: 100%;
  box-shadow: 2px 2px 16px #1a43591a;
  border-radius: 4px;
}

.brief-card:hover .info-container .link-container a {
          --text-opacity: 1;
          color: #ffffff;
          color: rgba(255, 255, 255, var(--text-opacity));
          --bg-opacity: 1;
          background-color: #00AEf0;
          background-color: rgba(0, 174, 240, var(--bg-opacity));
        }

.brief-card:hover .info-container .link-container a i:before, .brief-card:hover .info-container .link-container a [class^="icon-"]:before, .brief-card:hover .info-container .link-container a [class*=" icon-"]:before {
              --text-opacity: 1;
              color: #ffffff;
              color: rgba(255, 255, 255, var(--text-opacity));
            }

.brief-card .img-container {
    flex: 1 1 100%;
  }

.brief-card .img-container img {
      width: 100%;
      object-fit: cover;
      height: 160px;
      border-radius: 2px;
    }

.brief-card .info-container .title {
      margin-top: 1.5rem;
      margin-bottom: 0.75rem;
    }

.brief-card .info-container .summary p {
        --text-opacity: 1;
        color: #264E72;
        color: rgba(38, 78, 114, var(--text-opacity));
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        font-size: 1.25rem;
      }

.brief-card .info-container .link-container {
      margin-top: 1.5rem;
    }

.brief-card .info-container .link-container a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        padding-left: 1rem;
        padding-right: 1rem;
        --text-opacity: 1;
        color: #00AEf0;
        color: rgba(0, 174, 240, var(--text-opacity));
        background-color: rgba(var(--reserva-clara-rgb), 0.06);
        border-radius: 4px;
        transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
      }

.brief-card .info-container .link-container a [class^="icon-"]:before, .brief-card .info-container .link-container a [class*=" icon-"]:before {
            --text-opacity: 1;
            color: #00AEf0;
            color: rgba(0, 174, 240, var(--text-opacity));
            transition: color 0.25s ease-in-out;
          }
