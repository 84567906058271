:root {
  --sm-breakpoint: 640px;
  --md-breakpoint: 768px;
  --lg-breakpoint: 1024px;
  --xl-breakpoint: 1280px;
  --xxl-breakpoint: 1600px;
}

.pregunta {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  --bg-opacity: 1;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  box-shadow: 2px 2px 16px #1a43591a;
}

.pregunta:not(:last-child) {
    margin-bottom: 1rem;
  }

.pregunta input[type="checkbox"] {
    display: none;
  }

/* TODO: Remover en SharePoint, manejar este estado a través de la clase 'minus', se tiene aquí únicamente para fines de maquetación */

.pregunta input[type="checkbox"]:checked + label span:before {
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
          }

.pregunta input[type="checkbox"]:checked + label span:after {
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
          }

.pregunta input[type="checkbox"]:checked ~ .respuesta {
        margin-top: 1.5rem;
        max-height: 1000vh;
      }

.pregunta label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1rem;
    --text-opacity: 1;
    color: #264E72;
    color: rgba(38, 78, 114, var(--text-opacity));
    width: 100%;
  }

@media (min-width: 768px) {

.pregunta label {
      font-size: 1.5rem
  }
    }

.pregunta label span:not(.plus-minus) {
      width: calc(100% - 50px);
    }

.pregunta .respuesta {
    --text-opacity: 1;
    color: #264E72;
    color: rgba(38, 78, 114, var(--text-opacity));
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out, margin-top 0.5s ease-in-out;
  }

@media (min-width: 768px) {

.pregunta .respuesta {
      font-size: 1.5rem
  }
    }

.pregunta .respuesta p {
      --text-opacity: 1;
      color: #264E72;
      color: rgba(38, 78, 114, var(--text-opacity));
    }

@media (min-width: 768px) {

.pregunta .respuesta p {
        font-size: 1.5rem;

        line-height: 36px
    }
      }

.pregunta .respuesta ul {
      padding: revert;
    }

.pregunta .respuesta ul > li:before {
          margin-right: 1rem;
          color: var(--reserva-clara);
          content: "\2022";
          font-size: 1.5rem;
        }

.pregunta .respuesta ul ul {
        list-style-type: none;
      }

.pregunta .respuesta ul ul li {
          line-height: 36px;
        }

.pregunta .respuesta table {
      width: 100%;
    }

@media (min-width: 1024px) {

.pregunta .respuesta table {
        width: 75%
    }
      }

@media (min-width: 1280px) {

.pregunta .respuesta table {
        width: 50%
    }
      }

.pregunta .respuesta table tbody tr {
          display: flex;
          justify-content: space-between;
          padding-left: 1rem;
          padding-right: 1rem;
          padding-top: 0.75rem;
          padding-bottom: 0.75rem;
          background-color: rgba(var(--reserva-oscura-rgb), 0.06);
        }

.pregunta .respuesta table tbody tr:not(:last-child) {
            margin-bottom: 0.5rem;
          }
