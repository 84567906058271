:root {
  --sm-breakpoint: 640px;
  --md-breakpoint: 768px;
  --lg-breakpoint: 1024px;
  --xl-breakpoint: 1280px;
  --xxl-breakpoint: 1600px;
}

#solicitud > .container {
    margin-top: 2rem;
  }

@media (min-width: 768px) {

#solicitud > .container {
      margin-top: 4rem
  }
    }

@media (min-width: 1024px) {

#solicitud > .container {
      max-width: 1040px
  }
    }

#solicitud > .container .intro {
      margin-bottom: 2.5rem;
    }

#solicitud > .container .intro p {
        --text-opacity: 1;
        color: #264E72;
        color: rgba(38, 78, 114, var(--text-opacity));
      }

@media (min-width: 768px) {

#solicitud > .container .intro p {
          font-size: 1.5rem
      }
        }

#solicitud > .container .solicitud-form h3 {
        --text-opacity: 1;
        color: #264E72;
        color: rgba(38, 78, 114, var(--text-opacity));
        margin-bottom: 1.5rem;
      }

@media (min-width: 768px) {

#solicitud > .container .solicitud-form h3:not(:first-child) {
            display: inline-block;

            margin-top: 2rem
        }
          }

#solicitud > .container .solicitud-form fieldset {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        margin-bottom: 2rem;
        grid-column-gap: 2.5rem;
        -webkit-column-gap: 2.5rem;
        column-gap: 2.5rem;
        grid-row-gap: 1.5rem;
        row-gap: 1.5rem;
      }

@media (min-width: 768px) {

#solicitud > .container .solicitud-form fieldset {
          grid-template-columns: repeat(2, minmax(0, 1fr))
      }
        }

#solicitud > .container .solicitud-form fieldset .field {
          display: grid;
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }

#solicitud > .container .solicitud-form fieldset .field label {
            --text-opacity: 1;
            color: #264E72;
            color: rgba(38, 78, 114, var(--text-opacity));
            margin-bottom: 0.5rem;
            font-weight: 600;
            font-size: 1rem;
          }

#solicitud > .container .solicitud-form fieldset .field input,
          #solicitud > .container .solicitud-form fieldset .field select,
          #solicitud > .container .solicitud-form fieldset .field textarea {
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
            padding-left: 1rem;
            padding-right: 1rem;
            --bg-opacity: 1;
            background-color: #ffffff;
            background-color: rgba(255, 255, 255, var(--bg-opacity));
            font-size: 1.25rem;
            border: 2px solid #264e723d;
            border-radius: 4px;
          }

#solicitud > .container .solicitud-form .submit-container {
        display: flex;
        justify-content: center;
        align-items: center;
      }

#solicitud > .container .solicitud-form .submit-container button {
          width: 100%;
        }

@media (min-width: 768px) {

#solicitud > .container .solicitud-form .submit-container button {
            width: 50%
        }
          }
