[data-v-f2aaa597]:root {
  --sm-breakpoint: 640px;
  --md-breakpoint: 768px;
  --lg-breakpoint: 1024px;
  --xl-breakpoint: 1280px;
  --xxl-breakpoint: 1600px;
}
.new[data-v-f2aaa597] {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-gap: 1.5rem;
  gap: 1.5rem;
  padding: 1.5rem;
  background-color: var(--reserva-blanco);
  box-shadow: 2px 2px 16px #1a43591a;
  border-radius: 4px;
}
@media (min-width: 768px) {
.new[data-v-f2aaa597] {
    grid-template-columns: repeat(2, minmax(0, 1fr))
}
}
.new.highlight[data-v-f2aaa597] {
    grid-template-columns: repeat(1, minmax(0, 1fr));
}
@media (min-width: 1280px) {
.new.highlight[data-v-f2aaa597] {
      grid-row: span 2 / span 2
}
}
@media (min-width: 640px) {
.new.highlight .img-container[data-v-f2aaa597] {
        height: 410px
}
}
.new.highlight .news-content .news-summary[data-v-f2aaa597] {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
}
.new .img-container[data-v-f2aaa597] {
    height: 190px;
}
@media (min-width: 768px) {
.new .img-container[data-v-f2aaa597] {
      height: 250px
}
}
.new .img-container img[data-v-f2aaa597] {
      object-fit: cover;
      height: 100%;
      width: 100%;
      border-radius: 4px;
}
.new .news-content[data-v-f2aaa597] {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    grid-gap: 1rem;
    gap: 1rem;
}
.new .news-content .news-title[data-v-f2aaa597] {
      font-size: 1rem;
      color: var(--reserva-oscura);
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
}
@media (min-width: 768px) {
.new .news-content .news-title[data-v-f2aaa597] {
        font-size: 1.25rem
}
}
@media (min-width: 1024px) {
.new .news-content .news-title[data-v-f2aaa597] {
        font-size: 1.5rem
}
}
.new .news-content .news-summary[data-v-f2aaa597] {
      --text-opacity: 1;
      color: #264E72;
      color: rgba(38, 78, 114, var(--text-opacity));
      font-size: 14px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
}
@media (min-width: 768px) {
.new .news-content .news-summary[data-v-f2aaa597] {
        font-size: 1rem
}
}
@media (min-width: 1280px) {
.new .news-content .news-summary[data-v-f2aaa597] {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4
}
}
@media (min-width: 1600px) {
.new .news-content .news-summary[data-v-f2aaa597] {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5
}
}
.new .news-content .news-info[data-v-f2aaa597] {
      flex: 1 1 0%;
      display: flex;
      justify-content: space-between;
}
.new .news-content .news-info .date[data-v-f2aaa597] {
        font-weight: 700;
        color: var(--reserva-grafito);
}
.new .news-content .news-info .link-container a[data-v-f2aaa597] {
          font-weight: 700;
          color: var(--reserva-clara);
}
