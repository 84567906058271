#contacts > .container {
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
    @media (max-width: 1023px) {#contacts > .container {
      margin-top: 4rem;

      padding-left: 0;

      padding-right: 0;

      max-width: none
  }
    }
    @media (min-width: 1280px) {#contacts > .container {
      grid-template-columns: repeat(2, minmax(0, 1fr))
  }
    }
    @media (max-width: 1279px) {
    #contacts > .container .contact-form {
        margin-bottom: 2rem
    }
      }
    @media (min-width: 1024px) {
    #contacts > .container .contact-form {
        padding-left: 3rem;

        padding-right: 3rem
    }
      }
    @media (min-width: 1280px) {
    #contacts > .container .contact-form {
        padding-left: 5rem;

        padding-right: 5rem
    }
      }
    @media (max-width: 1023px) {
    #contacts > .container .contact-form .container {
          margin-left: auto;

          margin-right: auto
      }
        }
    #contacts > .container .contact-form h3 {
        margin-bottom: 0.5rem;
        --text-opacity: 1;
        color: #264E72;
        color: rgba(38, 78, 114, var(--text-opacity));
      }
    #contacts > .container .contact-form h4 {
        --text-opacity: 1;
        color: #00AEf0;
        color: rgba(0, 174, 240, var(--text-opacity));
      }
    #contacts > .container .contact-form .form {
        margin-top: 1.5rem;
      }
    #contacts > .container .contact-form .form .field:not(:last-child) {
            margin-bottom: 1rem;
          }
    @media (min-width: 1024px) {
    #contacts > .container .contact-form .form .field:not(:last-child) {
              margin-bottom: 1.5rem
          }
            }
    #contacts > .container .contact-form .form .submit-container {
          margin-top: 0.5rem;
        }
    @media (min-width: 1024px) {
    #contacts > .container .contact-form .form .submit-container {
            margin-top: 0.75rem
        }
          }
    #contacts > .container .contact-form .form .submit-container button {
            width: 100%;
          }
    @media (max-width: 1023px) {
    #contacts > .container .maps-container {
        padding-top: 2rem;

        padding-bottom: 2rem;

        --bg-opacity: 1;

        background-color: #ffffff;

        background-color: rgba(255, 255, 255, var(--bg-opacity));

        width: 100vw
    }

        #contacts > .container .maps-container .container {
          margin-left: auto;
          margin-right: auto;
        }
      }
    #contacts > .container .maps-container .offices-container {
        overflow-x: auto;
        white-space: nowrap;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
      }
    #contacts > .container .maps-container .offices-container .office-selector .office {
            display: inline-block;
          }
    #contacts > .container .maps-container .offices-container .office-selector .office:not(:last-child) {
              margin-right: 1.5rem;
            }
    #contacts > .container .maps-container .offices-container .office-selector .office.active button {
                --text-opacity: 1;
                color: #ffffff;
                color: rgba(255, 255, 255, var(--text-opacity));
                --bg-opacity: 1;
                background-color: #264E72;
                background-color: rgba(38, 78, 114, var(--bg-opacity));
              }
    #contacts > .container .maps-container .offices-container .office-selector .office button {
              padding-top: 0.5rem;
              padding-bottom: 0.5rem;
              padding-left: 1rem;
              padding-right: 1rem;
              --text-opacity: 1;
              color: #264E72;
              color: rgba(38, 78, 114, var(--text-opacity));
              font-weight: 600;
              border-radius: 4px;
              background-color: rgba(var(--reserva-oscura-rgb), 0.08);
            }
    #contacts > .container .maps-container .offices-container .office-selector .office button:active,
              #contacts > .container .maps-container .offices-container .office-selector .office button:focus,
              #contacts > .container .maps-container .offices-container .office-selector .office button:hover {
                outline: none;
              }
    #contacts > .container .maps-container .map-container {
        margin-bottom: 2rem;
        width: 95%;
        height: 400px;
      }
    #contacts > .container .maps-container .map-container .marker-container img {
            width: auto;
            height: auto;
            -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
          }
    #contacts > .container .maps-container .map-container #office-map {
          border-radius: 4px;
        }
    #contacts > .container .maps-container .address {
        --text-opacity: 1;
        color: #264E72;
        color: rgba(38, 78, 114, var(--text-opacity));
        font-size: 1.25rem;
      }
    #contacts > .container .maps-container .address h5 {
          font-weight: 700;
          margin-bottom: 1rem;
        }
    #contacts > .container .maps-container .address .address-section {
          display: flex;
          grid-gap: 0.75rem;
          gap: 0.75rem;
        }
    #contacts > .container .maps-container .address .address-section:not(:last-child) {
            margin-bottom: 1rem;
          }
    #contacts > .container .maps-container .address .address-section .icon-container i:before {
                --text-opacity: 1;
                color: #264E72;
                color: rgba(38, 78, 114, var(--text-opacity));
              }

@media (max-width: 1023px) {

#contacts + #footer {
    margin-top: 0
}
  }
