[data-v-05af8f00]:root {
  --sm-breakpoint: 640px;
  --md-breakpoint: 768px;
  --lg-breakpoint: 1024px;
  --xl-breakpoint: 1280px;
  --xxl-breakpoint: 1600px;
}
#page-header[data-v-05af8f00] {
  position: relative;
  padding-top: 1.5rem;
  padding-bottom: 2.5rem;
  height: 205px;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
#page-header[data-v-05af8f00] {
    padding-top: 3rem;

    padding-bottom: 3rem;

    height: 300px
}
}
#page-header.slim[data-v-05af8f00] {
    padding-top: 1.5rem;
    padding-bottom: 0;
    --bg-opacity: 1;
    background-color: #264E72;
    background-color: rgba(38, 78, 114, var(--bg-opacity));
    height: auto;
}
@media (min-width: 768px) {
#page-header.slim[data-v-05af8f00] {
      padding-top: 2rem;

      padding-bottom: 0.5rem
}
}
#page-header .container[data-v-05af8f00] {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    z-index: 3;
}
#page-header .container .breadcrumbs[data-v-05af8f00] {
      display: block;
      overflow: auto;
      white-space: nowrap;
      padding-bottom: 1.5rem;
      flex: 1 1 100%;
}
#page-header .container .breadcrumbs li[data-v-05af8f00] {
        display: inline-block;
        position: relative;
}
#page-header .container .breadcrumbs li:not(:first-child) a[data-v-05af8f00] {
            margin-left: 0.5rem;
}
@media (min-width: 768px) {
#page-header .container .breadcrumbs li:not(:first-child) a[data-v-05af8f00] {
              margin-left: 1.25rem
}
}
#page-header .container .breadcrumbs li:not(:last-child) a[data-v-05af8f00] {
            margin-right: 0.5rem;
}
@media (min-width: 768px) {
#page-header .container .breadcrumbs li:not(:last-child) a[data-v-05af8f00] {
              margin-right: 1.25rem
}
}
#page-header .container .breadcrumbs li:not(:last-child) i[data-v-05af8f00]:before, #page-header .container .breadcrumbs li:not(:last-child) [class^="icon-"][data-v-05af8f00]:before, #page-header .container .breadcrumbs li:not(:last-child) [class*=" icon-"][data-v-05af8f00]:before {
              font-size: 0.75rem;
              color: var(--reserva-clara);
}
@media (min-width: 768px) {
#page-header .container .breadcrumbs li:not(:last-child) i[data-v-05af8f00]:before, #page-header .container .breadcrumbs li:not(:last-child) [class^="icon-"][data-v-05af8f00]:before, #page-header .container .breadcrumbs li:not(:last-child) [class*=" icon-"][data-v-05af8f00]:before {
                font-size: 1rem
}
}
#page-header .container .breadcrumbs li a[data-v-05af8f00] {
          font-size: 0.9rem;
          color: var(--reserva-blanco);
}
@media (min-width: 768px) {
#page-header .container .breadcrumbs li a[data-v-05af8f00] {
            font-size: 1rem
}
}
#page-header .container h2[data-v-05af8f00] {
      flex: 1 1 100%;
      font-size: 1.5rem;
      color: var(--reserva-blanco);
}
@media (min-width: 1024px) {
#page-header .container h2[data-v-05af8f00] {
        font-size: 3rem
}
}
#page-header .background-container[data-v-05af8f00] {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
}
#page-header .background-container img[data-v-05af8f00] {
      height: 100%;
      width: 100%;
      object-fit: cover;
      z-index: 1;
}
#page-header .background-container .overlay[data-v-05af8f00] {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: 2;
      background: linear-gradient(180deg, #264e72 0%, #304d6f99 100%) 0% 0%
        no-repeat padding-box;
}
