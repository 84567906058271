:root {
  --sm-breakpoint: 640px;
  --md-breakpoint: 768px;
  --lg-breakpoint: 1024px;
  --xl-breakpoint: 1280px;
  --xxl-breakpoint: 1600px;
}

.gallery-container {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-gap: 1.5rem;
  gap: 1.5rem;
  margin-top: 2rem;
}

@media (min-width: 1024px) {

.gallery-container {
    grid-template-columns: repeat(2, minmax(0, 1fr));

    margin-top: 3rem
}
  }

@media (min-width: 1280px) {

.gallery-container {
    grid-template-columns: repeat(3, minmax(0, 1fr));

    margin-top: 4rem
}
  }

.gallery-container .gallery-card img {
      border-radius: 2px;
    }
