#forma-parte > .container {
    margin-left: auto;
    margin-right: auto;
  }
    #forma-parte > .container #intro {
      margin-top: 4rem;
    }
    #forma-parte > .container #intro p {
        margin-bottom: 1rem;
        color: var(--reserva-oscura);
        font-size: 1rem;
      }
    @media (min-width: 768px) {
    #forma-parte > .container #intro p {
          font-size: 1.5rem
      }
        }
    #forma-parte > .container #intro ul li {
          font-size: 1.5rem;
          color: var(--reserva-oscura);
        }
    #forma-parte > .container #intro ul li:not(:last-of-type) {
            margin-bottom: 1rem;
          }
    #forma-parte > .container #intro ul li:before {
            margin-right: 1rem;
            color: var(--reserva-clara);
            content: "\2022";
            font-size: 1.5rem;
          }
    @media (min-width: 640px) {
    #forma-parte > .container .form-link-container {
        display: inline-block
    }
      }
    #forma-parte > .container .form-link-container #enlace-formulario {
        display: flex;
        align-items: center;
        width: 100%;
        text-align: center;
        margin-top: 2rem;
        text-transform: uppercase;
      }
    @media (max-width: 639px) {
    #forma-parte > .container .form-link-container #enlace-formulario {
          justify-content: center
      }
        }
    @media (min-width: 768px) {
    #forma-parte > .container .form-link-container #enlace-formulario {
          width: auto
      }
        }
    #forma-parte > .container .form-link-container #enlace-formulario:hover i {
            opacity: 1;
          }
    #forma-parte > .container .form-link-container #enlace-formulario i {
          opacity: 0.5;
          transition: opacity 0.25s ease-in-out;
        }
    #forma-parte > .container .form-link-container #enlace-formulario i:before {
            margin-left: 1rem;
          }
    #forma-parte > .container .video-container {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      grid-gap: 2.5rem;
      gap: 2.5rem;
      margin-top: 4rem;
    }
    @media (min-width: 1024px) {
    #forma-parte > .container .video-container {
        grid-template-columns: repeat(2, minmax(0, 1fr))
    }
      }
    #forma-parte > .container .video-container .video iframe {
          width: 100%;
          max-height: 430px;
          aspect-ratio: 4/3;
          border-radius: 4px;
        }
    #forma-parte > .container #beneficios-container {
      margin-top: 4rem;
    }
    #forma-parte > .container #beneficios-container h2 {
        margin-bottom: 2rem;
      }
    #forma-parte > .container #beneficios-container .beneficios {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-gap: 1rem;
        gap: 1rem;
      }
    @media (min-width: 1024px) {
    #forma-parte > .container #beneficios-container .beneficios {
          grid-template-columns: repeat(2, minmax(0, 1fr))
      }
        }
    #forma-parte > .container #beneficios-container .beneficios .beneficio {
          padding-left: 3rem;
          padding-right: 1.5rem;
          padding-top: 2rem;
          padding-bottom: 2rem;
          font-weight: 600;
          --text-opacity: 1;
          color: #264E72;
          color: rgba(38, 78, 114, var(--text-opacity));
          background-color: rgba(var(--reserva-oscura-rgb), 0.06);
          border-radius: 4px;
          font-size: 1rem;
        }
    @media (min-width: 768px) {
    #forma-parte > .container #beneficios-container .beneficios .beneficio {
            font-size: 1.5rem
        }
          }
    #forma-parte > .container #beneficios-container .beneficios .beneficio:before {
            content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
            color: var(--reserva-clara); /* Change the color */
            font-weight: bold; /* If you want it to be bold */
            display: inline-block; /* Needed to add space between the bullet and the text */
            width: 1em; /* Also needed for space (tweak if needed) */
            margin-left: -1em; /* Also needed for space (tweak if needed) */
          }
    #forma-parte > .container #programa-pasantia {
      margin-top: 4rem;
    }
    #forma-parte > .container #programa-pasantia h2 {
        margin-bottom: 1.5rem;
      }
    #forma-parte > .container #programa-pasantia .intro p {
          color: var(--reserva-oscura);
          font-size: 1rem;
        }
    #forma-parte > .container #programa-pasantia .intro p:not(:last-child) {
            margin-bottom: 1rem;
          }
    @media (min-width: 768px) {
    #forma-parte > .container #programa-pasantia .intro p {
            font-size: 1.5rem
        }
          }
    #forma-parte > .container #programa-pasantia .steps-container {
        display: inline-block;
        white-space: nowrap;
        max-width: 100%;
        overflow-x: scroll;
      }
    @media (min-width: 1600px) {
    #forma-parte > .container #programa-pasantia .steps-container {
          overflow-x: auto
      }
        }
    #forma-parte > .container #programa-pasantia .steps-container .steps {
          display: flex;
          flex-wrap: nowrap;
          margin-top: 2rem;
          margin-bottom: 2rem;
          margin-left: 1rem;
          margin-right: 1rem;
        }
    #forma-parte > .container #programa-pasantia .steps-container .steps li {
            display: flex;
            align-items: center;
          }
    #forma-parte > .container #programa-pasantia .steps-container .steps li .step {
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              align-content: space-between;
              padding: 1.5rem;
              --bg-opacity: 1;
              background-color: #ffffff;
              background-color: rgba(255, 255, 255, var(--bg-opacity));
              --text-opacity: 1;
              color: #264E72;
              color: rgba(38, 78, 114, var(--text-opacity));
              flex: 1 0 auto;
              width: 200px;
              height: 140px;
              border-radius: 4px;
              box-shadow: 2px 2px 16px #1a43591a;
              font-weight: 600;
            }
    #forma-parte > .container #programa-pasantia .steps-container .steps li .step .step-icon img {
                  margin-left: auto;
                  margin-right: auto;
                }
    #forma-parte > .container #programa-pasantia .steps-container .steps li .step .name {
                display: block;
                width: 100%;
                text-align: center;
                white-space: pre-line;
                line-height: 1rem;
              }
    #forma-parte > .container #programa-pasantia .steps-container .steps li .icon-container {
              margin-left: 1rem;
              margin-right: 1rem;
              opacity: 0.5;
            }
    #forma-parte > .container #programa-pasantia .req-container p {
          margin-bottom: 1rem;
          color: var(--reserva-oscura);
          font-size: 1rem;
        }
    @media (min-width: 768px) {
    #forma-parte > .container #programa-pasantia .req-container p {
            font-size: 1.5rem
        }
          }
    #forma-parte > .container #programa-pasantia .req-container .white-box h3 {
            margin-bottom: 2rem;
            color: var(--reserva-oscura);
          }
    #forma-parte > .container #programa-pasantia .req-container .white-box .reqs .req {
              --text-opacity: 1;
              color: #264E72;
              color: rgba(38, 78, 114, var(--text-opacity));
              counter-increment: list;
              font-size: 1rem;
              font-weight: 600;
            }
    @media (min-width: 768px) {
    #forma-parte > .container #programa-pasantia .req-container .white-box .reqs .req {
                font-size: 1.5rem
            }
              }
    #forma-parte > .container #programa-pasantia .req-container .white-box .reqs .req:before {
                margin-right: 1rem;
                --text-opacity: 1;
                color: #00AEf0;
                color: rgba(0, 174, 240, var(--text-opacity));
                content: counter(list) ".";
                font-size: 1rem;
              }
    @media (min-width: 768px) {
    #forma-parte > .container #programa-pasantia .req-container .white-box .reqs .req:before {
                  font-size: 1.5rem
              }
                }
