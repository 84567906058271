.filtro-documentos .datepicker-container[data-v-2d33fb00] {
    --bg-opacity: 1;
    background-color: #ffffff;
    background-color: rgba(255, 255, 255, var(--bg-opacity));
}
.filtro-documentos .datepicker-container .datepicker[data-v-2d33fb00] {
      position: relative;
}
.filtro-documentos .datepicker-container .datepicker #document-year[data-v-2d33fb00] {
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
}
