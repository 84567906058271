:root {
  --sm-breakpoint: 640px;
  --md-breakpoint: 768px;
  --lg-breakpoint: 1024px;
  --xl-breakpoint: 1280px;
  --xxl-breakpoint: 1600px;
}

#activities > .container {
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
  }

@media (min-width: 1024px) {

#activities > .container {
      margin-top: 4rem
  }
    }

#activities > .container .intro {
      margin-bottom: 1.5rem;
    }

@media (min-width: 1024px) {

#activities > .container .intro {
        margin-bottom: 2rem
    }
      }

#activities > .container .intro p {
        --text-opacity: 1;
        color: #264E72;
        color: rgba(38, 78, 114, var(--text-opacity));
      }

@media (min-width: 1024px) {

#activities > .container .intro p {
          font-size: 1.5rem
      }
        }
