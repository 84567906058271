:root {
  --sm-breakpoint: 640px;
  --md-breakpoint: 768px;
  --lg-breakpoint: 1024px;
  --xl-breakpoint: 1280px;
  --xxl-breakpoint: 1600px;
}

#image-slider.splide {
    height: 700px;
  }

@media (max-width: 767px) {

#image-slider.splide .splide__arrows {
        position: absolute;

        display: flex;

        align-content: center;

        bottom: 0;

        left: 0;

        right: 0;

        height: 38.5px
    }
      }

#image-slider.splide .splide__arrows .splide__arrow {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
        width: auto;
        height: auto;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 4px;
      }

@media (max-width: 767px) {

#image-slider.splide .splide__arrows .splide__arrow {
          background: none;

          z-index: 2
      }
        }

#image-slider.splide .splide__arrows .splide__arrow .icon-container span {
            font-size: 3rem;
          }

@media (max-width: 767px) {

#image-slider.splide .splide__arrows .splide__arrow .icon-container span {
              font-size: 1rem
          }
            }

#image-slider.splide .splide__arrows .splide__arrow.splide__arrow--prev {
          margin-left: 4rem;
        }

@media (min-width: 768px) {

#image-slider.splide .splide__arrows .splide__arrow.splide__arrow--prev {
            margin-left: 0
        }
          }

@media (min-width: 1024px) {

#image-slider.splide .splide__arrows .splide__arrow.splide__arrow--prev {
            margin-left: 2rem
        }
          }

@media (min-width: 1600px) {

#image-slider.splide .splide__arrows .splide__arrow.splide__arrow--prev {
            margin-left: 4rem
        }
          }

#image-slider.splide .splide__arrows .splide__arrow.splide__arrow--next {
          margin-right: 4rem;
        }

@media (min-width: 768px) {

#image-slider.splide .splide__arrows .splide__arrow.splide__arrow--next {
            margin-right: 0
        }
          }

@media (min-width: 1024px) {

#image-slider.splide .splide__arrows .splide__arrow.splide__arrow--next {
            margin-right: 2rem
        }
          }

@media (min-width: 1600px) {

#image-slider.splide .splide__arrows .splide__arrow.splide__arrow--next {
            margin-right: 4rem
        }
          }

#image-slider.splide .splide__track {
      height: 100%;
    }

#image-slider.splide .splide__track .splide__list {
        height: 100%;
      }

#image-slider.splide .splide__track .splide__list .splide__slide {
          display: flex;
        }

#image-slider.splide .splide__track .splide__list .splide__slide .container {
            display: flex;
            margin-left: auto;
            margin-right: auto;
          }

#image-slider.splide .splide__track .splide__list .splide__slide .container .background-image {
              position: absolute;
              z-index: -1;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
            }

#image-slider.splide .splide__track .splide__list .splide__slide .container .background-image .desktop {
                height: 100%;
                object-fit: cover;
              }

@media (max-width: 1023px) {

#image-slider.splide .splide__track .splide__list .splide__slide .container .background-image .desktop {
                  display: none
              }
                }

#image-slider.splide .splide__track .splide__list .splide__slide .container .background-image .mobile {
                height: 100%;
                object-fit: cover;
              }

@media (min-width: 1024px) {

#image-slider.splide .splide__track .splide__list .splide__slide .container .background-image .mobile {
                  display: none
              }
                }

#image-slider.splide .splide__track .splide__list .splide__slide .container .background {
              position: absolute;
              height: 100%;
              width: 100%;
              bottom: 0;
              left: 0;
            }

@media (max-width: 767px) {

#image-slider.splide .splide__track .splide__list .splide__slide .container .background {
                background: linear-gradient(
                  0deg,
                  rgba(2, 0, 36, 1) 0%,
                  rgba(255, 255, 255, 1) 0%,
                  rgba(255, 255, 255, 0.5) 40%,
                  rgba(255, 255, 255, 0) 50%
                );

                -webkit-animation: SlideInFromBottom ease-in-out 0.75s;

                animation: SlideInFromBottom ease-in-out 0.75s
            }
              }

@media (min-width: 768px) {

#image-slider.splide .splide__track .splide__list .splide__slide .container .background {
                background: linear-gradient(
                  90deg,
                  rgba(2, 0, 36, 1) 0%,
                  rgba(255, 255, 255, 1) 0%,
                  rgba(255, 255, 255, 0) 50%,
                  rgba(255, 255, 255, 0) 100%
                );

                -webkit-animation: SlideInFromLeft ease-in-out 0.75s;

                animation: SlideInFromLeft ease-in-out 0.75s
            }
              }

#image-slider.splide .splide__track .splide__list .splide__slide .container .content {
              position: relative;
              display: flex;
              flex-wrap: wrap;
              align-content: flex-end;
              margin-bottom: 3rem;
            }

@media (max-width: 767px) {

#image-slider.splide .splide__track .splide__list .splide__slide .container .content {
                justify-content: center
            }
              }

@media (min-width: 768px) {

#image-slider.splide .splide__track .splide__list .splide__slide .container .content {
                align-content: center;

                margin-bottom: 0;

                margin-left: 4rem;

                flex-basis: 40%
            }
              }

#image-slider.splide .splide__track .splide__list .splide__slide .container .content .slider-info {
                width: 100%;
              }

#image-slider.splide .splide__track .splide__list .splide__slide .container .content .slider-info h1 {
                  padding-bottom: 0.75rem;
                  font-size: 2rem;
                  color: var(--reserva-oscura);
                  flex: 1 0 100%;
                }

@media (min-width: 768px) {

#image-slider.splide .splide__track .splide__list .splide__slide .container .content .slider-info h1 {
                    font-size: 3.5rem
                }
                  }

@media (min-width: 1024px) {

#image-slider.splide .splide__track .splide__list .splide__slide .container .content .slider-info h1 {
                    font-size: 5.25rem
                }
                  }

#image-slider.splide .splide__track .splide__list .splide__slide .container .content .slider-info h2 {
                  padding-bottom: 2rem;
                  flex: 1 1 0%;
                  font-weight: 400;
                  white-space: pre-line;
                  font-size: 1.25rem;
                  color: var(--reserva-oscura);
                  flex: 1 0 100%;
                }

@media (min-width: 768px) {

#image-slider.splide .splide__track .splide__list .splide__slide .container .content .slider-info h2 {
                    font-size: 1.5rem
                }
                  }

@media (min-width: 1024px) {

#image-slider.splide .splide__track .splide__list .splide__slide .container .content .slider-info h2 {
                    font-size: 2rem
                }
                  }

#image-slider.splide .splide__track .splide__list .splide__slide .container .content .slider-info a {
                  width: 100%;
                  text-align: center;
                }

@media (min-width: 768px) {

#image-slider.splide .splide__track .splide__list .splide__slide .container .content .slider-info a {
                    width: auto;

                    padding-left: 4rem;

                    padding-right: 4rem
                }
                  }

#image-slider.splide .splide__pagination {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      background-color: var(--reserva-blanco);
      bottom: 0;
    }

@media (min-width: 768px) {

#image-slider.splide .splide__pagination {
        padding-top: 0;

        padding-bottom: 0;

        margin-bottom: 1.5rem;

        bottom: 0.5rem;

        background-color: transparent
    }
      }

#image-slider.splide .splide__pagination li:not(:last-child) {
          margin-right: 0.5rem;
        }

#image-slider.splide .splide__pagination li button {
          height: 10px;
          width: 10px;
          background-color: var(--reserva-oscura);
          opacity: 0.5;
        }

@media (min-width: 768px) {

#image-slider.splide .splide__pagination li button {
            height: 20px;

            width: 20px
        }
          }

#image-slider.splide .splide__pagination li button.is-active {
            background-color: var(--reserva-clara);
            opacity: 1;
          }
