.responsive-grid[data-v-95f5a78d] {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 2.5rem;
    gap: 2.5rem;
}
@media (min-width: 768px) {.responsive-grid[data-v-95f5a78d] {
      grid-template-columns: repeat(2, minmax(0, 1fr))
}
}
@media (min-width: 1024px) {.responsive-grid[data-v-95f5a78d] {
      grid-template-columns: repeat(3, minmax(0, 1fr))
}
}
@media (min-width: 1280px) {.responsive-grid[data-v-95f5a78d] {
      grid-template-columns: repeat(4, minmax(0, 1fr))
}
}
#press-notes > .container[data-v-95f5a78d] {
    margin-top: 4rem;
}
#press-notes > .container .filter .date-published[data-v-95f5a78d] {
        position: relative;
}
#press-notes > .container .filter .date-published label[data-v-95f5a78d] {
          padding-top: 0.75rem;
          padding-bottom: 0.75rem;
          padding-right: 2rem;
          padding-left: 0.75rem;
          --text-opacity: 1;
          color: #264E72;
          color: rgba(38, 78, 114, var(--text-opacity));
          border: 2px solid rgba(var(--reserva-oscura-rgb), 0.24);
          border-radius: 4px;
}
#press-notes > .container .filter .date-published label span[data-v-95f5a78d] {
            margin-right: 0.75rem;
}
#press-notes > .container .filter .date-published input[data-v-95f5a78d] {
          position: absolute;
          width: 0;
          height: 0;
          opacity: 0;
          bottom: 0;
          left: 0;
}
